import { useEffect } from 'react';

import FormFeedback from 'components/shared/form-feedback';
import useAnalytics from 'hooks/use-analytics';
import { ApiRequestError } from 'providers/api/helpers';

type Props = {
  error: Error;
  variables: string | Blob | undefined;
  shopId: string;
  productId: number;
};

const PhotoUploadError = ({ error, variables, shopId, productId }: Props) => {
  const { trackDisplayedPhotoValidationError } = useAnalytics();

  let errorDetails: string[] = [];
  let isUnprocessableError = false;
  let isFileTypeError = false;
  let displayMessage =
    'Something went wrong uploading your image. Please try again.';

  if (error instanceof ApiRequestError) {
    isUnprocessableError = error.status === 422;
    isFileTypeError = error.status === 415;

    if (isUnprocessableError && error.responseBody?.messages) {
      displayMessage = error.responseBody.messages.join(' ');
      errorDetails = error.responseBody.messages;
    } else if (isFileTypeError) {
      displayMessage = 'Image file type must be one of jpg, jpeg, png.';
      errorDetails = [displayMessage];
    }
  }

  useEffect(
    () => {
      if (isUnprocessableError || isFileTypeError) {
        const fileType =
          typeof variables === 'object' ? (variables as Blob).type : undefined;

        trackDisplayedPhotoValidationError({
          shopId,
          productId,
          page: 'customer segments',
          input: 'menu product upload',
          errorDetails: errorDetails,
          displayedErrorMessage: displayMessage,
          fileType: fileType,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return <FormFeedback>{displayMessage}</FormFeedback>;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PhotoUploadError;
