import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

export const RegisterTipSettingsSchema = z.object({
  adjustmentLimitPercent: z.string(),
  defaultTips: z.array(
    z.object({ amount: z.string(), amountType: z.string() }),
  ),
});

export const getRegisterTipSettingsQueryKey = (shopId: Shop['shopId']) => [
  shopId,
  'registerTipSettings',
];

export const useRegisterTipSettings = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getRegisterTipSettingsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/payment_settings`,
      );

      return RegisterTipSettingsSchema.parse(camelCaseKeys(response));
    },
  });
};
