import { CalendarDate } from '@internationalized/date';
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import queryString from 'query-string';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import {
  CreateCustomerExportResponse,
  CustomerType,
} from 'types/customers/profile/customer-profile';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getCustomerExportsQueryKey } from './use-customer-exports';

type Params = {
  customerType: CustomerType;
  dates?: RangeValue<CalendarDate>;
};

export const getCustomerExportsMutationKey = (shopId: Shop['shopId']) =>
  [shopId, 'customerExport', 'mutation'] as const;

export const useCreateCustomersExportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
): UseMutationResult<CreateCustomerExportResponse, ApiRequestError, Params> => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values) => {
      const params = {
        customerType: values.customerType,
        forceUpdate: true,
        start: values.dates
          ? toStartOfDateAbsoluteString(values.dates.start, shopTimezone)
          : undefined,
        finish: values.dates
          ? toEndOfDateAbsoluteString(values.dates.end, shopTimezone)
          : undefined,
      };

      const query = `?${queryString.stringify(snakeCaseKeys(params))}`;
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/customer_downloads${query}`,
      );

      return camelCaseKeys(response) as CreateCustomerExportResponse;
    },
    mutationKey: getCustomerExportsMutationKey(shopId),
    onSuccess: () => {
      queryClient.invalidateQueries(getCustomerExportsQueryKey(shopId));
    },
  });
};
