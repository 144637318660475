import * as externalUrls from 'routes/external-urls';
import * as paths from 'routes/paths';
import {
  getCustomerAnalyticsPath,
  getCustomerProfilesPath,
  getCustomerSegmentsPath,
} from 'routes/paths/customers';
import {
  getShopHasBasicRegister,
  getShopHasFullRegister,
} from 'utilities/shops';

export const topSection = (shopId, isHoursAnalyticsEnabled, isMobile) => {
  const sectionItems = [];

  const homeItem = {
    icon: 'house',
    label: 'Home',
    url: paths.shop(shopId),
    dataTestId: isMobile ? 'mobileNavItemHome' : 'desktopNavItemHome',
  };
  sectionItems.push(homeItem);

  const menuItem = {
    activeWith: [/shops\/\d+\/menu/],
    icon: 'menu',
    label: 'Menu',
    url: paths.getMenuItemsPath(shopId),
    items: [
      {
        activeWith: [/shops\/\d+\/menu\/items\/\w+/],
        label: 'Items',
        url: paths.getMenuItemsPath(shopId),
      },
      {
        activeWith: [/shops\/\d+\/menu\/categories\/\w+/],
        label: 'Categories',
        url: paths.getMenuCategoriesPath(shopId),
      },
      {
        label: 'Online Menu Layout',
        url: paths.getMenuLayoutPath(shopId),
      },
    ],
  };
  sectionItems.push(menuItem);

  const hoursItem = {
    icon: 'clock',
    label: 'Hours',
    url: paths.shopHours(shopId),
    activeWith: [
      paths.shopHoursDelivery(shopId),
      paths.shopHoursWeekly(shopId),
      paths.shopClosings(shopId),
    ],
  };

  if (isHoursAnalyticsEnabled) {
    hoursItem.activeWith.push(paths.shopHoursAnalytics(shopId));

    const hoursOverviewSubItem = {
      label: 'Overview',
      url: paths.shopHours(shopId),
      activeWith: [
        paths.shopHoursDelivery(shopId),
        paths.shopHoursWeekly(shopId),
        paths.shopClosings(shopId),
      ],
    };

    const hoursAnalyticsSubItem = {
      label: 'Hours Analytics',
      url: paths.shopHoursAnalytics(shopId),
    };

    hoursItem.items = [hoursOverviewSubItem, hoursAnalyticsSubItem];
  }
  sectionItems.push(hoursItem);

  return {
    name: 'first',
    items: sectionItems,
  };
};

export const secondSection = (shopId, isCustomerProfilesPageEnabled) => {
  const sectionItems = [];

  const financialsItem = {
    icon: 'money',
    label: 'Financials',
    url: paths.getFinancialsOrdersPath(shopId, 'online'),
    items: [
      {
        label: 'Orders',
        url: paths.getFinancialsOrdersPath(shopId, 'online'),
        activeWith: [
          paths.getFinancialsOrdersPath(shopId, 'online'),
          paths.getFinancialsOrdersPath(shopId, 'register'),
        ],
      },
      {
        label: 'Statements',
        url: paths.getFinancialsStatementsPath(shopId),
      },
      {
        label: 'Payouts',
        url: paths.getFinancialsPayoutsPath(shopId),
      },
      {
        label: 'Reports',
        url: paths.getFinancialsReportsOrderActivityPath(shopId),
        activeWith: [/shops\/\d+\/financials\/reports\/\w+/],
      },
    ],
  };

  sectionItems.push(financialsItem);

  const customersItem = {
    icon: 'people',
    label: 'Customers',
    url: getCustomerAnalyticsPath(shopId),
    items: [
      {
        label: 'Analytics',
        url: getCustomerAnalyticsPath(shopId),
      },
      {
        label: 'Segments',
        url: getCustomerSegmentsPath(shopId),
      },
      {
        label: 'Feedback',
        url: paths.getCustomerFeedbackPath(shopId),
      },
    ],
  };

  if (isCustomerProfilesPageEnabled) {
    const profilesItem = {
      label: 'Profiles',
      url: getCustomerProfilesPath(shopId),
    };

    customersItem.items.splice(2, 0, profilesItem);
  }

  sectionItems.push(customersItem);

  const analyticsItem = {
    icon: 'graph',
    label: 'Analytics',
    url: paths.shopAnalyticsOrders(shopId),
    activeWith: [paths.shopAnalyticsCustomers(shopId)],
  };
  sectionItems.push(analyticsItem);

  return { name: 'second', items: sectionItems };
};

export const thirdSection = (
  shopId,
  isBrandManagerPageEnabled,
  isUpsellAMSEnabled,
  isGoogleBusinessProfilePageEnabled,
) => {
  const sectionItems = [];
  const marketingItems = [];

  const discountsItem = {
    label: 'Discounts',
    url: paths.automaticDiscounts(shopId),
    // Match both promo codes and online discounts, including their respective create and
    // and edit pages.
    activeWith: [/shops\/\d+\/marketing\/discounts(\/[\d-]+)?/],
  };

  marketingItems.push(discountsItem);

  marketingItems.push({
    label: 'Branding Studio',
    url: paths.assetLibrary(shopId),
    activeWith: paths.assetLibrary(shopId),
  });

  if (isUpsellAMSEnabled) {
    marketingItems.push({
      label: 'Marketing Services',
      url: paths.shopMarketing(shopId),
      activeWith: paths.shopMarketing(shopId),
    });
  }

  let campaignManagerUrl = paths.campaignManager(shopId);

  marketingItems.push({
    label: 'Digital Ads Report',
    url: campaignManagerUrl,
    activeWith: campaignManagerUrl,
  });

  const marketingParentNavItem = {
    icon: 'bubble',
    label: 'Marketing',
    url: marketingItems[0].url,
    items: marketingItems,
  };
  sectionItems.push(marketingParentNavItem);

  const websiteItems = [];

  const smartButtonsItem = {
    label: 'Smart Buttons',
    url: paths.shopSmartButtons(shopId),
  };
  websiteItems.push(smartButtonsItem);

  const smartPopupsItem = {
    label: 'Smart Popups',
    url: paths.shopSmartPopups(shopId),
  };
  websiteItems.push(smartPopupsItem);

  const customWebsiteItem = {
    label: 'Custom Website',
    url: paths.shopCustomWebsite(shopId),
  };
  websiteItems.push(customWebsiteItem);

  const sliceOrderingItem = {
    label: 'Classic Buttons',
    url: paths.shopPartnerButtonsCreateCustomButton(shopId),
    activeWith: [
      paths.shopPartnerButtonsCreateCustomButton(shopId),
      paths.shopPartnerButtonsGetLink(shopId),
    ],
  };
  websiteItems.push(sliceOrderingItem);

  const websiteParentItem = {
    icon: 'website',
    label: 'Website Tools',
    url: paths.shopSmartButtons(shopId),
    activeWith: [
      paths.shopPartnerButtonsCreateCustomButton(shopId),
      paths.shopPartnerButtonsGetLink(shopId),
      paths.shopCustomWebsite(shopId),
      paths.shopSmartButtons(shopId),
      paths.shopSmartPopups(shopId),
    ],
    items: websiteItems,
  };
  sectionItems.push(websiteParentItem);

  const onlinePresenceItem = {
    icon: 'verification',
    label: 'Online Presence',
    url: paths.shopScore(shopId),
    activeWith: [paths.shopScore(shopId)],
    items: [
      {
        label: 'Pizzeria Score',
        url: paths.shopScore(shopId),
      },
    ],
  };

  if (isGoogleBusinessProfilePageEnabled) {
    onlinePresenceItem.items.push({
      label: 'Google Business Profile',
      url: paths.googleBusinessProfile(shopId),
      activeWith: [/shops\/\d+\/online-presence\/google-business-profile/],
    });
  }

  if (isBrandManagerPageEnabled) {
    onlinePresenceItem.items.push({
      label: 'Brand Manager',
      url: paths.brandManager(shopId),
      activeWith: paths.brandManager(shopId),
    });
  }

  sectionItems.push(onlinePresenceItem);

  return { name: 'third', items: sectionItems };
};

export const registerSection = ({ shop }) => {
  const { shopId } = shop;
  const registerNavItem = {
    icon: 'register',
    label: 'Register',
  };

  if (getShopHasFullRegister(shop)) {
    registerNavItem.url = paths.registerLayout(shopId);

    registerNavItem.items = [
      {
        label: 'Register Menu Layout',
        url: paths.registerLayout(shopId),
        activeWith: [/shops\/\d+\/register\/layout/],
      },
      {
        label: 'Employee Permissions',
        url: paths.registerUsers(shopId),
        activeWith: [
          /shops\/\d+\/register\/(users|permissions|log)(\/[\d-]+)?/,
        ],
      },
      {
        label: 'Printer Settings',
        url: paths.shopRegisterPrinting(shopId),
      },
      {
        label: 'Register Discounts',
        url: paths.registerDiscounts(shopId),
        activeWith: [/shops\/\d+\/register\/discounts(\/[\d-]+)?/],
      },
      {
        label: 'Tip Settings',
        url: paths.registerTipSettings(shopId),
      },
      {
        label: 'System Profiles',
        url: paths.registerProfiles(shopId),
      },
      {
        label: 'User Guide',
        url: externalUrls.registerUserGuide,
      },
    ];
  } else {
    registerNavItem.url = paths.getRegisterSplashPath(shopId);

    if (getShopHasBasicRegister(shop)) {
      registerNavItem.activeWith = [/shops\/\d+\/register/];

      registerNavItem.items = [
        {
          label: 'Learn More',
          url: paths.getRegisterSplashPath(shopId),
        },
        {
          label: 'Printer Settings',
          url: paths.shopRegisterPrinting(shopId),
        },
      ];
    }
  }

  return {
    name: 'register',
    items: [registerNavItem],
  };
};

export const phoneSection = (shopId, isPhonePageEnabled) => {
  const sectionItems = [];

  if (isPhonePageEnabled) {
    const phoneNavItem = {
      icon: 'phone',
      label: 'Phone',
      url: paths.getPhonePath(shopId),
    };
    sectionItems.push(phoneNavItem);
    return {
      name: 'phone',
      items: sectionItems,
    };
  }

  return null;
};

export const operationsSection = (shopId) => {
  const navItems = [];

  const operationsNavItem = {
    icon: 'shop',
    label: 'Operations',
    url: paths.operations(shopId),
  };

  navItems.push(operationsNavItem);

  return {
    name: 'operations',
    items: navItems,
  };
};

export const accountsSection = (shopId, isSlicePaymentsEnabled) => {
  const sectionItems = [];

  const accountNavItem = {
    icon: 'gear',
    label: 'Account',
    url: paths.shopIntegrations(shopId),
    items: [
      {
        label: 'POS Integrations',
        url: paths.shopIntegrations(shopId),
      },
      {
        label: 'Help Center',
        url: externalUrls.helpCenter,
      },
    ],
  };

  if (isSlicePaymentsEnabled) {
    accountNavItem.items.splice(1, 0, {
      label: 'Payout Management',
      url: paths.debitCards(shopId),
      activeWith: [paths.debitCards(shopId), paths.debitCardNew(shopId)],
    });
  }

  sectionItems.push(accountNavItem);

  return { name: 'account', items: sectionItems };
};
