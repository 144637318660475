import { Navigate } from 'react-router-dom';

import ContentTile from 'components/shared/content-tile';
import Loading from 'components/shared/loading';
import { useShopHasGoogleConnection } from 'hooks/business-profile/use-shop-has-google-connection';
import { Shop } from 'types/shops';

import { Results } from '../results';

type Props = {
  shopId: Shop['shopId'];
};

export const Profile = ({ shopId }: Props) => {
  const { hasGoogleConnection, isLoading: isGoogleConnectionLoading } =
    useShopHasGoogleConnection(shopId);

  if (isGoogleConnectionLoading) {
    return <Loading />;
  } else if (!hasGoogleConnection) {
    return <Navigate to="login" replace />;
  }

  return (
    <div>
      <ContentTile>
        <h2>Business Profile Status</h2>
      </ContentTile>
      <Results shopId={shopId} />
    </div>
  );
};
