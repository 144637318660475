import { Route, Routes } from 'react-router-dom';

import { Shop } from 'types/shops';

import { Login } from './login';
import { Profile } from './profile';

type Props = {
  shopId: Shop['shopId'];
};

export const GoogleBusinessProfile = ({ shopId }: Props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Profile shopId={shopId} />} />
        <Route path="login" element={<Login shopId={shopId} />} />
      </Routes>
    </>
  );
};
