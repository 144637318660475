export type NetworkRegisterPrinter = {
  default_printing_enabled: boolean;
  id: string;
  name: string;
  number_of_copies: number;
  print_mode: string;
  shop_id: string; // Note: This is actually the shop's UUID.
};

export enum PrintMode {
  AfterPayments = 'After Payments',
  Incremental = 'Incremental',
}

export type RegisterPrinter = {
  defaultPrintingEnabled: boolean;
  id: string;
  name: string;
  numberOfCopies: number;
  printMode: PrintMode;
  shopUuid: string;
};

export type PrintModeSelectOption = {
  label: string;
  value: string;
};

export type RegisterPrinterFormValues = {
  name: string;
  numberOfCopies: number;
  printMode: PrintModeSelectOption;
};
