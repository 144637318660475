import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops/shop';
import { camelCaseKeys } from 'utilities/objects';

import { ResponseShopGoogleOauthToken } from './types';
import { getQueryKey as getShopHasGoogleConnectionQueryKey } from './use-shop-has-google-connection';

export const useExchangeGoogleAuthCodeMutation = () => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      shopId,
      authorizationCode,
    }: {
      shopId: Shop['shopId'];
      authorizationCode: string;
    }) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/google_oauth_token`,
        {
          authorization_code: authorizationCode,
        },
      );
      return camelCaseKeys(response) as ResponseShopGoogleOauthToken;
    },
    onSuccess: (data, { shopId }) => {
      queryClient.setQueryData(
        getShopHasGoogleConnectionQueryKey(shopId),
        data,
      );
    },
  });
};
