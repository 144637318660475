import { ComponentPropsWithoutRef } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import FormFeedback from 'components/shared/form-feedback';
import InputDescription from 'components/shared/input-description';
import Label from 'components/shared/label';
import Button, { ButtonVariant } from 'components/shared/slice-button';
import TextArea from 'components/shared/text-area';
import { useCreateCustomerFeedbackReplyMutation } from 'hooks/customer-feedback/use-create-customer-feedback-reply-mutation';
import useAnalytics from 'hooks/use-analytics';
import {
  CustomerReview,
  CustomerReviewReplyFormValues,
} from 'types/customer-feedback';
import { Shop } from 'types/shops';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';

import styles from './styles.module.scss';

type Props = {
  review: CustomerReview;
  onCancel: () => void;
  shopId: Shop['shopId'];
} & ComponentPropsWithoutRef<'form'>;

export const ReplyForm = ({ review, onCancel, shopId, ...props }: Props) => {
  const { trackSubmitReview } = useAnalytics();

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
  } = useForm<CustomerReviewReplyFormValues>({
    defaultValues: {
      reply: '',
    },
    mode: 'onTouched',
  });

  const { mutate: saveReply } = useCreateCustomerFeedbackReplyMutation(
    shopId,
    review,
  );

  const handleValidSubmit = (values: CustomerReviewReplyFormValues) =>
    saveReply(values, {
      onError: () => showUnexpectedErrorToast(),
      onSuccess: () => {
        toast.success('Reply submitted and is now in review.');

        trackSubmitReview(shopId, {
          feedback_response_reply: {
            body: values.reply,
          },
        });
      },
    });

  return (
    <form
      onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
      {...props}
    >
      <div
        className={styles.field}
        data-chameleon-target="Customer Reviews Response Textbox"
      >
        <Label htmlFor={`${review.id}-reply`}>Your Response</Label>
        <TextArea
          aria-describedby={`${review.id}-description`}
          id={`${review.id}-reply`}
          isInvalid={errors.reply != null}
          placeholder="1000 character max"
          {...register('reply', {
            required: 'Please enter a response.',
            minLength: {
              value: 5,
              message: 'Please enter at least 5 characters.',
            },
            maxLength: {
              value: 1000,
              message: 'Please enter no more than 1000 characters.',
            },
          })}
        />
        <FormFeedback>{errors.reply?.message}</FormFeedback>
        <InputDescription
          id={`${review.id}-description`}
          className={styles.notes}
        >
          Note: you can only reply once and a customer will not be able to reply
          back. Once approved, your response will be posted and visible online
          to other customers.
        </InputDescription>
      </div>
      <div className={styles.controls}>
        <Button
          className={styles.button}
          disabled={isSubmitting}
          onClick={onCancel}
          variant={ButtonVariant.Tertiary}
        >
          Cancel
        </Button>
        <Button
          className={styles.button}
          disabled={isSubmitting}
          type="submit"
          variant={ButtonVariant.Primary}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};
