import { Dispatch, SetStateAction, useRef } from 'react';
import { ColumnFiltersState } from '@tanstack/react-table';

import { Button, IconButton } from 'crust';

import { MenuProductsResponseBody } from 'types/menu/api';

import styles from './styles.module.scss';

type CategoryFiltersProps = {
  categories: MenuProductsResponseBody['categories'];
  categoryFilters: ColumnFiltersState;
  setCategoryFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
};

type HandleHorizontalScrollProps = {
  element: HTMLDivElement | null;
  direction: 'left' | 'right';
};

const handleHorizontalScroll = ({
  element,
  direction,
}: HandleHorizontalScrollProps) => {
  if (!element) {
    return 'Element not found';
  }

  const distance = 500 * (direction === 'right' ? 1 : -1);

  element.scrollBy({
    left: distance,
    behavior: 'smooth',
  });
};

export const CategoryFilters = ({
  categories,
  categoryFilters,
  setCategoryFilters,
}: CategoryFiltersProps) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const isAllSelected = categoryFilters.length === 0;

  return (
    <div className={styles.categoryContainer}>
      <IconButton
        icon="chevronLeft"
        className={styles.moreChevron}
        onPress={() =>
          handleHorizontalScroll({
            element: elementRef.current,
            direction: 'left',
          })
        }
      />
      <Button
        variant={isAllSelected ? 'primary' : 'secondary'}
        className={styles.label}
        onPress={() => setCategoryFilters([])}
      >
        All
      </Button>
      <div className={styles.scrollContainer} ref={elementRef}>
        {categories.map((category) => {
          const isActive = categoryFilters[0]?.value === category.name;

          return (
            // Replace buttons in this component with a the crust Label when it's available
            <Button
              key={category.id}
              variant={isActive ? 'primary' : 'secondary'}
              className={styles.label}
              onPress={() =>
                setCategoryFilters([
                  {
                    id: 'category',
                    value: category.name,
                  },
                ])
              }
            >
              {category.name}
            </Button>
          );
        })}
      </div>
      <IconButton
        icon="chevronRight"
        className={styles.moreChevron}
        onPress={() =>
          handleHorizontalScroll({
            element: elementRef.current,
            direction: 'right',
          })
        }
      />
    </div>
  );
};
