import { QueryKey, useQuery } from '@tanstack/react-query';
import { ColumnSort, PaginationState } from '@tanstack/react-table';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  GetPayoutsRequestParams,
  Payout,
  PayoutsResponse,
} from 'types/payouts';
import { Shop } from 'types/shops';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';
import { toSnakeCase } from 'utilities/strings';

export const getPayoutsQueryKey = (
  shopId: Shop['shopId'],
  params: GetPayoutsRequestParams,
): QueryKey => [shopId, 'payouts', params];

export const usePayoutsQuery = (
  shopId: number,
  pagination: PaginationState,
  sorting: ColumnSort,
) => {
  const { authenticatedFetch } = useApi();

  const params = {
    page: pagination.pageIndex + 1,
    perPage: pagination.pageSize,
    sort: `${sorting.desc ? '-' : '+'}${toSnakeCase(sorting.id)}`,
  };

  return useQuery({
    queryKey: getPayoutsQueryKey(shopId, params),
    queryFn: async () => {
      const query = queryString.stringify(snakeCaseKeys(params));
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/payouts?${query}`,
        {},
        true,
        true,
        true,
      );

      // Normalize the response pagination values to be a part of the response
      // similar to OPAS rather than dealing with headers outside API layer.
      return {
        payouts: camelCaseKeys(response.body) as Payout[],
        meta: {
          pages: Number(response.headers.get('X-Total-Pages') ?? 0),
          results: Number(response.headers.get('X-Total') ?? 0),
        },
      } as PayoutsResponse;
    },
    keepPreviousData: true,
  });
};
