import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuCategoryResponseBody } from 'types/menu/api';
import { MenuCategory, MenuCategoryFormValues } from 'types/menu/category';
import { Shop } from 'types/shops';
import { getCategoryBody } from 'utilities/menu';

import { getMenuProductsQueryKey } from './use-menu-products-query';
import { getMenuQueryKey } from './use-menu-query';

export const useSaveCategoryMutation = (
  shopId: Shop['shopId'],
  original?: MenuCategory,
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: MenuCategoryFormValues) => {
      const body = getCategoryBody(values, original);
      const isNew = typeof body.id === 'string';
      const response = isNew
        ? await authenticatedFetch.post(
            `api/management/v2/shops/${shopId}/menus/categories`,
            body,
          )
        : await authenticatedFetch.put(
            `api/management/v2/shops/${shopId}/menus/categories/${body.id}`,
            body,
          );

      return response as MenuCategoryResponseBody;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getMenuQueryKey(shopId));
      queryClient.invalidateQueries(getMenuProductsQueryKey(shopId));
    },
  });
};
