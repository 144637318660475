import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop, ShopRatingsResponse } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

const getShopRatingsQueryKey = (shopId: Shop['shopId']) =>
  [shopId, 'ratings'] as const;

export const useShopRatingsQuery = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getShopRatingsQueryKey(shopId),
    queryFn: async (): Promise<ShopRatingsResponse> => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/ratings`,
      );

      return camelCaseKeys(response);
    },
  });
};
