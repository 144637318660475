import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { SingleRegisterOrder } from 'types/register-orders';

type Props = {
  orderId: string;
  shopId: string;
};

export const getSingleOrderQueryKey = ({
  orderId,
  shopId,
}: Props): QueryKey => ['register-orders', orderId, shopId];

export const useRegisterOrderQuery = ({ orderId, shopId }: Props) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getSingleOrderQueryKey({ orderId, shopId }),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v2/ros/shops/${shopId}/orders/${orderId}`,
        {},
        true,
        true,
        true,
      );

      return response.body as SingleRegisterOrder;
    },
    enabled: !!orderId,
  });
};
