import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { CampaignManagerSummary } from 'types/campaign-manager';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

type UseCampaignManagerSummaryQueryOptions = Omit<
  UseQueryOptions<CampaignManagerSummary>,
  'queryKey' | 'queryFn'
>;

export const getCampaignManagerSummaryQueryKey = (
  shopId: Shop['shopId'],
): QueryKey => [shopId, 'campaign-manager-summary'];

const useCampaignManagerSummaryQuery = (
  shopId: Shop['shopId'],
  options?: UseCampaignManagerSummaryQueryOptions,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getCampaignManagerSummaryQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/metrics/advanced_marketing_services/summary`,
      );
      return camelCaseKeys(response) as CampaignManagerSummary;
    },
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useCampaignManagerSummaryQuery;
