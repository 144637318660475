import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { networkRecommendedPermissionGroupsResponseToDomainInfo } from 'utilities/register-users';

export const getRecommendedRegisterPermissionGroupsQueryKey = (): QueryKey => [
  'recommendedPermissionGroups',
];

export const useRecommendedRegisterPermissionGroupsQuery = () => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getRecommendedRegisterPermissionGroupsQueryKey(),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/recommended_permission_groups`,
      );

      return networkRecommendedPermissionGroupsResponseToDomainInfo(response);
    },
  });
};
