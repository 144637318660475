import { QueryKey, useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  GetShopStatusHistoryRequestParams,
  GetShopStatusHistoryResponse,
} from 'types/hours-analytics';
import { Shop } from 'types/shops';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

export const getShopStatusHistoryQueryKey = (
  shopId: Shop['shopId'],
  params: GetShopStatusHistoryRequestParams,
): QueryKey => [shopId, 'shop-status-history', params];

type Props = {
  shopId: Shop['shopId'];
  params: GetShopStatusHistoryRequestParams;
};

const useShopStatusHistoryQuery = ({ shopId, params }: Props) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getShopStatusHistoryQueryKey(shopId, params),
    queryFn: async () => {
      const query = `?${queryString.stringify(snakeCaseKeys(params))}`;
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/hours/log${query}`,
      );

      return camelCaseKeys(response) as GetShopStatusHistoryResponse;
    },
    keepPreviousData: true,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useShopStatusHistoryQuery;
