import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterProfile } from 'types/register-profile';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

type UseRegisterProfilesQueryOptions = Omit<
  UseQueryOptions<RegisterProfile[]>,
  'queryFn' | 'queryKey'
>;

export const getRegisterProfilesQueryKey = (
  shopId: Shop['shopId'],
): QueryKey => [shopId, 'registerProfiles'];

export const useRegisterProfilesQuery = (
  shopId: Shop['shopId'],
  options?: UseRegisterProfilesQueryOptions,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getRegisterProfilesQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${shopId}/register_profiles`,
      );

      return camelCaseKeys(response) as RegisterProfile[];
    },
    ...options,
  });
};
