import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  NetworkCreateEditRegisterPermissionGroupResponse,
  PermissionsFormValues,
  PermissionsInfo,
  RegisterPermission,
  RegisterPermissionGroup,
} from 'types/register-users';
import { Shop } from 'types/shops';
import { upsertBy } from 'utilities/lists';

export const useSavePermisionsGroupMutation = (
  isNew: boolean,
  shopId: Shop['shopId'],
  permissionIdsByCategoryName: Map<string, string[]>,
  permissionGroupId: string,
  recommendedPermissionGroups?: PermissionsInfo,
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: PermissionsFormValues) => {
      const data = {
        name: values.name,
        permissions: values.permissionIds.filter(
          (it) => !permissionIdsByCategoryName.has(it),
        ),
      };
      const response = isNew
        ? await authenticatedFetch.post(
            `api/management/v1/ros/shops/${shopId}/permission_groups`,
            {
              name: data.name,
              permissions: data.permissions,
            },
          )
        : await authenticatedFetch.put(
            `api/management/v1/ros/shops/${shopId}/permission_groups/${permissionGroupId}`,
            {
              name: data.name,
              permissions: data.permissions,
              id: permissionGroupId,
            },
          );

      return response as NetworkCreateEditRegisterPermissionGroupResponse;
    },
    onSuccess: (response: NetworkCreateEditRegisterPermissionGroupResponse) => {
      queryClient.setQueryData<RegisterPermissionGroup[]>(
        [shopId, 'permissionGroups'],
        (old = []) => {
          // The response from a create or edit unfortunately only gives us the permission ids back for a group
          // compared to the get response which gives more details on a permission group
          // To update the cache we can use the all permissions to map the ids to full details and use this
          // to update our cache for the created or edited permission group.
          const fullPermissions: RegisterPermission[] = [];
          response.permissions.forEach((permissionId) => {
            const fullPermission =
              recommendedPermissionGroups?.permissions.find(
                (permission) => permission.id === permissionId,
              );
            if (fullPermission) {
              fullPermissions.push(fullPermission);
            }
          });
          const newPermissionGroup: RegisterPermissionGroup = {
            id: response.id,
            name: response.name,
            shopUuid: response.shop_id,
            permissions: fullPermissions,
          };
          return upsertBy(old, newPermissionGroup, 'id');
        },
      );
    },
  });
};
