import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CustomerCounts,
  CustomerType,
  DateRange,
  RegistrationType,
} from 'types/customers';
import { createCustomerFilter, getInterval } from 'utilities/customers';
import { camelCaseKeys } from 'utilities/objects';

type UseCustomerCountsQueryOptions = Omit<
  UseQueryOptions<CustomerCounts>,
  'queryKey' | 'queryFn'
>;

type Props = {
  customerType: CustomerType;
  dateRange: DateRange;
  registrationType: RegistrationType;
  shopId: string;
  timezone: string;
  options?: UseCustomerCountsQueryOptions;
};

export const getCustomerCountsQueryKey = ({
  customerType,
  dateRange,
  registrationType,
  shopId,
}: Omit<Props, 'timezone'>): QueryKey => [
  shopId,
  'customerCounts',
  dateRange,
  customerType,
  registrationType,
];

const useCustomerCountsQuery = ({
  customerType,
  dateRange,
  registrationType,
  shopId,
  timezone,
  options,
}: Props): UseQueryResult<CustomerCounts> => {
  const { authenticatedFetch } = useApi();

  return useQuery<CustomerCounts>({
    queryKey: getCustomerCountsQueryKey({
      customerType,
      dateRange,
      registrationType,
      shopId,
    }),
    queryFn: async () => {
      const filters = [];
      if (customerType !== CustomerType.All) {
        filters.push(createCustomerFilter('order_type', 'is', customerType));
      }

      filters.push(
        createCustomerFilter('customer_type', 'is', registrationType),
      );

      const response = await authenticatedFetch.post(
        `api/v1/shops/${shopId}/marketing/customers/overview`,
        {
          filters: filters,
          interval: getInterval(dateRange, timezone),
        },
      );

      return camelCaseKeys(response) as CustomerCounts;
    },
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useCustomerCountsQuery;
