import { useMutation } from '@tanstack/react-query';

import useAnalytics from 'hooks/use-analytics';
import useApi from 'hooks/use-api';
import { ProductField } from 'utilities/menu';

export const useProductUpdateMutation = (
  productId: string,
  shopId: string,
  getProductFormValues: (name: string) => string,
) => {
  const { authenticatedFetch } = useApi();
  const { trackProductChangeRequest } = useAnalytics();

  return useMutation({
    mutationFn: async (values: { content: string }) => {
      const request = values.content;
      const productName = getProductFormValues(ProductField.Name);

      await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/products/${productId}/change_request`,
        {
          request_content: request,
          product_name: productName,
        },
      );
    },
    onSuccess: (_data, values) => {
      trackProductChangeRequest({
        id: productId,
        shopId,
        productRequestContent: values.content,
      });
    },
  });
};
