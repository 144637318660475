import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  NetworkGetRecommendedPermissionGroupsResponse,
  NetworkGetRegisterUsersResponse,
  NetworkRegisterPermissionGroup,
  networkToDomainRegisterPermissionGroup,
  PermissionsInfo,
  RecommendedPermissionGroup,
  RegisterPermission,
  RegisterPermissionGroup,
  RegisterUser,
  RegisterUserRowData,
} from 'types/register-users';
import {
  getRegisterUserRowData,
  networkGetRegisterUsersResponseToRegisterUsers,
  networkRecommendedPermissionGroupsResponseToDomainInfo,
} from 'utilities/register-users';

type UseRegisterUsers = {
  isLoading: boolean;
  permissionGroups: RegisterPermissionGroup[];
  permissions: RegisterPermission[];
  recommendedPermissionGroups: RecommendedPermissionGroup[];
  users: RegisterUser[];
  usersRowData: RegisterUserRowData[];
};

const useRegisterUsers = (shopId: string): UseRegisterUsers => {
  const { authenticatedFetch } = useApi();

  const { data: usersData, isLoading: isUsersLoading } = useQuery({
    queryKey: [shopId, 'registerUsers'],
    queryFn: async () => {
      return (await authenticatedFetch
        .get(`api/management/v1/ros/shops/${shopId}/register_users`)
        .then((response: NetworkGetRegisterUsersResponse): RegisterUser[] => {
          return networkGetRegisterUsersResponseToRegisterUsers(response);
        })) as RegisterUser[];
    },
  });

  const { data: permissionsInfo, isLoading: isRecommendedGroupsLoading } =
    useQuery({
      queryKey: ['recommendedPermissionGroups'],
      queryFn: async () => {
        return (await authenticatedFetch
          .get(`api/management/v1/ros/recommended_permission_groups`)
          .then((response: NetworkGetRecommendedPermissionGroupsResponse) => {
            return networkRecommendedPermissionGroupsResponseToDomainInfo(
              response,
            );
          })) as PermissionsInfo;
      },
    });

  const { data: groupsData, isLoading: isGroupsLoading } = useQuery({
    queryKey: [shopId, 'permissionGroups'],
    queryFn: async () => {
      return (await authenticatedFetch
        .get(`api/management/v1/ros/shops/${shopId}/permission_groups`)
        .then(
          (
            response: NetworkRegisterPermissionGroup[],
          ): RegisterPermissionGroup[] => {
            return response.map((networkRegisterPermissionGroup) => {
              return networkToDomainRegisterPermissionGroup(
                networkRegisterPermissionGroup,
              );
            });
          },
        )) as RegisterPermissionGroup[];
    },
  });

  let usersRowData: RegisterUserRowData[] = [];

  if (!isUsersLoading && !isGroupsLoading && usersData && groupsData) {
    usersRowData = getRegisterUserRowData({
      groupsData,
      usersData,
    });
  }

  return {
    isLoading: isUsersLoading || isRecommendedGroupsLoading || isGroupsLoading,
    permissionGroups: groupsData ?? [],
    permissions: permissionsInfo?.permissions ?? [],
    recommendedPermissionGroups:
      permissionsInfo?.recommendedPermissionGroups ?? [],
    users: usersData ?? [],
    usersRowData,
  };
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useRegisterUsers;
