import FileUploader from 'components/shared/file-uploader';

import PhotoCopyrightWarning from './copyright-warning';
import PhotoGuidelines from './guidelines';
import PhotoPreview from './photo-preview';
import PhotoUploadError from './photo-upload-error';
import RemovePhotoButton from './remove-photo-button';

import styles from './styles.module.scss';

type Props = {
  dataChameleonTarget?: string;
  error: Error;
  variables: string | Blob | undefined;
  isUploadInProgress: boolean;
  onChangeImage: (file: File) => void;
  onRemoveImage: () => void;
  productName: string;
  url: string;
  shopId: string;
  productId: number;
};

const PhotoUploader = ({
  dataChameleonTarget,
  error,
  isUploadInProgress,
  onChangeImage,
  onRemoveImage,
  productName,
  url,
  variables,
  shopId,
  productId,
}: Props) => {
  return (
    <div
      className={styles.photoUploaderContainer}
      data-chameleon-target={dataChameleonTarget}
    >
      <div>
        {url ? (
          <>
            <PhotoPreview productName={productName} url={url} />
            <RemovePhotoButton onRemoveImage={onRemoveImage} />
          </>
        ) : (
          <>
            <FileUploader
              disabled={isUploadInProgress}
              isInProgress={isUploadInProgress}
              onChange={onChangeImage}
              id="product-image-input"
            />
            {error && (
              <PhotoUploadError
                error={error}
                variables={variables}
                shopId={shopId}
                productId={productId}
              />
            )}
          </>
        )}
      </div>
      <PhotoGuidelines />
      <PhotoCopyrightWarning />
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default PhotoUploader;
