import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuResponseBody } from 'types/menu/api';
import { Shop } from 'types/shops';
import { sortRelationships } from 'utilities/menu';

type Options = Omit<UseQueryOptions<MenuResponseBody>, 'queryFn' | 'queryKey'>;

export const getMenuQueryKey = (
  shopId: Shop['shopId'] | string,
  channel?: 'online' | 'offline',
): QueryKey => {
  const key = [Number(shopId), 'menu'];
  if (channel) {
    key.push(channel);
  }
  return key;
};

export const useMenuQuery = (
  shopId: Shop['shopId'] | string,
  channel?: 'online' | 'offline',
  options?: Options,
) => {
  const { authenticatedFetch } = useApi();

  // This endpoint doesn't actually provide the full menu as it does not include
  // product addons (customizations). Restaurant API passes the request through
  // to Menu Service /management-api/v2/menus/{shopId} with the include depth hard-coded to `product-types`.
  return useQuery({
    queryKey: getMenuQueryKey(shopId, channel),
    queryFn: async (): Promise<MenuResponseBody> => {
      const param = channel ? `?channel=${channel}` : '';
      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/menus${param}`,
      );
      return sortRelationships(response);
    },
    staleTime: 60000,
    ...options,
  });
};
