import { CalendarDate } from '@internationalized/date';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import { CreateStandalonePaymentsReportResponse } from 'types/financials';
import { Shop } from 'types/shops/shop';
import { camelCaseKeys } from 'utilities/objects';

import { getStandalonePaymentsReportsQueryKey } from './use-standalone-payments-reports-query';

type Values = {
  dates: RangeValue<CalendarDate>;
};

export const useCreateStandalonePaymentsReportMutation = (
  shopId: Shop['shopId'],
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dates }: Values) => {
      const params = {
        start: dates.start.toString(),
        finish: dates.end.toString(),
      };
      const query = `${queryString.stringify(params)}`;
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/standalone_payments_reports?${query}`,
      );

      return camelCaseKeys(response) as CreateStandalonePaymentsReportResponse;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        getStandalonePaymentsReportsQueryKey(shopId),
      );
    },
  });
};
