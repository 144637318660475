import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterTipSettings } from 'types/register-tip-settings';
import { Shop } from 'types/shops';
import { snakeCaseKeys } from 'utilities/objects';

import { getRegisterTipSettingsQueryKey } from './use-register-tip-settings';

type SaveRegisterTipSettingsVariables = {
  shopId: Shop['shopId'];
  tipSettings: RegisterTipSettings;
};

export const useSaveRegisterTipSettings = () => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      shopId,
      tipSettings,
    }: SaveRegisterTipSettingsVariables) => {
      await authenticatedFetch.patch(
        `api/management/v1/shops/${shopId}/payment_settings`,
        snakeCaseKeys(tipSettings),
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        getRegisterTipSettingsQueryKey(variables.shopId),
      );
    },
  });
};
