import { QueryKey, useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  GetProfileCustomersParams,
  GetProfileCustomersResponse,
} from 'types/customers/profile/customer-profile';
import { Shop } from 'types/shops';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

export const getProfileCustomersQueryKey = (
  shopId: Shop['shopId'],
  params?: GetProfileCustomersParams,
): QueryKey => [shopId, 'profile-customers', ...(params ? [params] : [])];

type Props = {
  shopId: Shop['shopId'];
  params: GetProfileCustomersParams;
};

export const useProfileCustomersQuery = ({ shopId, params }: Props) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getProfileCustomersQueryKey(shopId, params),
    queryFn: async () => {
      const query = `?${queryString.stringify(snakeCaseKeys(params))}`;

      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/customers${query}`,
        {},
        true,
        true,
        true,
      );

      return {
        customers: camelCaseKeys(response.body.data),
        meta: camelCaseKeys(response.body.meta),
      } as GetProfileCustomersResponse;
    },
    keepPreviousData: true,
  });
};
