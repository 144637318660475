import { CalendarDate } from '@internationalized/date';
import { RangeValue } from '@react-types/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { CreateSalesSummaryReportResponse } from 'types/financials';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { camelCaseKeys } from 'utilities/objects';

import { getRegisterSalesSummaryReportsQueryKey } from './use-register-sales-summary-reports-query';

type Values = {
  dates: RangeValue<CalendarDate>;
};

export const useCreateRegisterSalesSummaryReportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dates }: Values) => {
      const params = {
        start: toStartOfDateAbsoluteString(dates.start, shopTimezone),
        finish: toEndOfDateAbsoluteString(dates.end, shopTimezone),
      };
      const query = `${queryString.stringify(params)}`;
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/sales_summary_reports?${query}`,
      );

      return camelCaseKeys(response) as CreateSalesSummaryReportResponse;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        getRegisterSalesSummaryReportsQueryKey(shopId),
      );
    },
  });
};
