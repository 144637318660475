import { RegisterUser } from './user';

export type RegisterPermission = {
  action: string;
  category: string;
  id: string;
  name: string;
};

export type NetworkRegisterPermissionGroup = {
  id: string;
  name: string;
  permissions: RegisterPermission[];
  shop_id: string;
};

export type RecommendedPermissionGroup = {
  name: string;
  permissions: string[];
};

export type NetworkGetRecommendedPermissionGroupsResponse = {
  permissions: RegisterPermission[];
  recommended_permission_groups: RecommendedPermissionGroup[];
};

export type PermissionsInfo = {
  permissions: RegisterPermission[];
  recommendedPermissionGroups: RecommendedPermissionGroup[];
};

export type RegisterPermissionGroup = {
  id: string;
  name: string;
  permissions: RegisterPermission[];
  shopUuid: string;
};

export type NetworkCreateEditRegisterPermissionGroupResponse = {
  id: string;
  name: string;
  permissions: string[];
  shop_id: string;
};

export const networkToDomainRegisterPermissionGroup = (
  networkRegisterPermissionGroup: NetworkRegisterPermissionGroup,
): RegisterPermissionGroup => {
  const { id, name, permissions, shop_id } = networkRegisterPermissionGroup;

  return {
    id,
    name,
    permissions,
    shopUuid: shop_id,
  };
};

export type UserPermissionGroup = {
  permissionGroupName: string;
  isInGroup: boolean;
};

export type PermissionsFormValues = {
  name: string;
  permissionIds: string[];
};

export type NetworkPermissionsToggleResponse = {
  setting: {
    register_user_permissions: boolean;
  };
};

export type PermissionCategory = {
  name: string;
  permissions: RegisterPermission[];
};

export type PermissionsByCategory = {
  name: string;
  permissions: RegisterPermission[];
};

export type CreateEditPermissionBody = {
  name: string;
  permissions: string[];
  id?: string;
};

export type CreatePermissionBody = {
  name: string;
  permissions: string[];
  id?: string;
};

export type GetRegisterRowDataProps = {
  groupsData: RegisterPermissionGroup[];
  usersData: RegisterUser[];
};
