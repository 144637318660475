import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CustomerList,
  CustomersAnalytics,
  CustomerType,
  DateRange,
  Interval,
  RegistrationType,
} from 'types/customers';
import { createCustomerFilter, getInterval } from 'utilities/customers';
import { camelCaseKeys } from 'utilities/objects';

type UseCustomersAnalyticsQueryOptions = Omit<
  UseQueryOptions<CustomersAnalytics>,
  'queryKey' | 'queryFn'
>;

type Props = {
  customerList: CustomerList;
  customerType: CustomerType;
  customerUuids?: string[];
  dateRange: DateRange;
  options?: UseCustomersAnalyticsQueryOptions;
  registrationType: RegistrationType;
  shopId: string;
  timezone: string;
};

export const getCustomersAnalyticsQueryKey = ({
  customerType,
  dateRange,
  registrationType,
  shopId,
  customerList,
  customerUuids,
}: Omit<Props, 'timezone'>): QueryKey => [
  shopId,
  'customersAnalytics',
  dateRange,
  customerType,
  registrationType,
  customerList,
  customerUuids,
];

const useCustomersAnalyticsQuery = ({
  customerList,
  customerType,
  customerUuids = [],
  dateRange,
  options,
  registrationType,
  shopId,
  timezone,
}: Props): UseQueryResult<CustomersAnalytics> => {
  const { authenticatedFetch } = useApi();

  const getCustomersAnalytics = async (
    shopId: string,
    interval: Interval,
    customerType: CustomerType,
    registrationType: RegistrationType,
    customerList: CustomerList,
    customerUuids: string[],
  ): Promise<CustomersAnalytics> => {
    const filters = [];

    // Filter by customer list
    if (customerList === CustomerList.New) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'new'),
      );
    } else if (customerList === CustomerList.Loyal) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'loyal'),
      );
    } else if (customerList === CustomerList.Repeat) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'repeat'),
      );
    } else if (customerList === CustomerList.Delivery) {
      filters.push(createCustomerFilter('shipping_type', 'is', 'delivery'));
    } else if (customerList === CustomerList.Pickup) {
      filters.push(createCustomerFilter('shipping_type', 'is', 'pickup'));
    } else if (
      customerList === CustomerList.MostFrequent ||
      customerList === CustomerList.MostValuable
    ) {
      filters.push(createCustomerFilter('customer_uuid', 'in', customerUuids));
    }

    // Filter by order type (online or register)
    if (customerType !== CustomerType.All) {
      filters.push(createCustomerFilter('order_type', 'is', customerType));
    }

    // Filter by customer type (registered or guest)
    filters.push(createCustomerFilter('customer_type', 'is', registrationType));

    const response = await authenticatedFetch.post(
      `api/v1/shops/${shopId}/marketing/customers/analytics`,
      {
        filters: filters,
        interval,
      },
    );

    return camelCaseKeys(response);
  };

  return useQuery<CustomersAnalytics>({
    queryKey: getCustomersAnalyticsQueryKey({
      customerType,
      dateRange,
      registrationType,
      shopId,
      customerList,
      customerUuids,
    }),
    queryFn: () =>
      getCustomersAnalytics(
        shopId,
        getInterval(dateRange, timezone),
        customerType,
        registrationType,
        customerList,
        customerUuids,
      ),
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useCustomersAnalyticsQuery;
