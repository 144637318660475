import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { GetPublishersResponse } from 'types/brand-manager';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

const getQueryKey = (shopId: Shop['shopId']): QueryKey =>
  [shopId, 'shopPublishers'] as const;

export const useShopPublishersQuery = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/brand_manager/publishers/${shopId}`,
      );

      return camelCaseKeys(response) as GetPublishersResponse;
    },
  });
};
