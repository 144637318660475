import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  RegisterProfile,
  RegisterProfileFormValues,
} from 'types/register-profile';
import { Shop } from 'types/shops';
import { upsertBy } from 'utilities/lists';
import { camelCaseKeys } from 'utilities/objects';

import { getRegisterProfilesQueryKey } from './use-register-profiles-query';

export const useSaveRegisterProfileMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: RegisterProfileFormValues) => {
      const body = {
        name: values.name.trim(),
      };
      const response = values.id
        ? await authenticatedFetch.put(
            `api/management/v1/ros/shops/${shopId}/register_profiles/${values.id}`,
            body,
          )
        : await authenticatedFetch.post(
            `api/management/v1/ros/shops/${shopId}/register_profiles`,
            body,
          );

      return camelCaseKeys(response) as RegisterProfile;
    },
    onSuccess: (data) => {
      queryClient.setQueryData<RegisterProfile[]>(
        getRegisterProfilesQueryKey(shopId),
        (current) => upsertBy(current ?? [], data, 'id'),
      );
    },
  });
};
