import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterUser } from 'types/register-users';
import { upsertBy } from 'utilities/lists';
import { networkRegisterUserToRegisterUser } from 'utilities/register-users';

import { getRegisterUsersQueryKey } from '.';

export const useResetRegisterUserPinMutation = (
  userId: string,
  shopId: string,
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await authenticatedFetch.post(
        `api/management/v1/ros/shops/${shopId}/register_users/${userId}/reset_pin`,
      );

      return networkRegisterUserToRegisterUser(response);
    },
    onSuccess(response: RegisterUser) {
      queryClient.setQueryData<RegisterUser[]>(
        getRegisterUsersQueryKey(shopId),
        (old = []) => upsertBy(old, response, 'id'),
      );
    },
  });
};
