import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuCategory } from 'types/menu/category';
import { Shop } from 'types/shops';

import { getCategoryQueryKey } from './use-category-query';
import { getMenuProductsQueryKey } from './use-menu-products-query';
import { getMenuQueryKey } from './use-menu-query';

type Values = {
  categoryId: MenuCategory['id'];
};

export const useDeleteCategoryMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ categoryId }: Values) => {
      await authenticatedFetch.del(
        `api/management/v2/shops/${shopId}/menus/categories/${categoryId}`,
      );
    },
    onSuccess: (_, { categoryId }) => {
      queryClient.removeQueries(getCategoryQueryKey(shopId, categoryId));
      queryClient.invalidateQueries(getMenuQueryKey(shopId));
      queryClient.invalidateQueries(getMenuProductsQueryKey(shopId));
    },
  });
};
