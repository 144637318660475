import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import { getShopsQueryKey } from './use-shops-query';

export const getShopQueryKey = (id: number | string): QueryKey => [
  ...getShopsQueryKey(),
  Number(id),
];

// Until all components read the current shop ID as a number and not a string,
// we'll allow the string and convert it to a number.
export const useShopQuery = (
  id: number | string,
  options?: UseQueryOptions<Shop>,
) => {
  const { authenticatedFetch } = useApi();

  options = {
    staleTime: 60000, // One minute.
    ...options,
  };

  return useQuery({
    queryKey: getShopQueryKey(id),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${id}?role=shop_owner`,
      );

      return camelCaseKeys(response) as Shop;
    },
    ...options,
  });
};
