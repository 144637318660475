import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ShopScoreResponse } from 'types/shops';

import { getShopQueryKey } from './use-shop-query';

export const getShopScoreQueryKey = (shopId: number): QueryKey => [
  ...getShopQueryKey(shopId),
  'shop-score',
];

// Until all components read the current shop ID as a number and not a string,
// we'll allow the string and convert it to a number.
export const useShopScoreQuery = (shopId: number | string) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getShopScoreQueryKey(Number(shopId)),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/scores`,
      );
      return response as ShopScoreResponse;
    },
  });
};
