import { flexRender, RowData } from '@tanstack/react-table';
import cx from 'classnames';

import Text from 'components/shared/text';
import {
  AutomaticTableBodyCellProps,
  BaseTableBodyCellProps,
  OverloadedTableBodyCellComponent,
} from 'types/shared/table';

import styles from './styles.module.scss';

const BaseTableBodyCell = ({
  hasRowStriping,
  chameleonId,
  chameleonTableTitle,
  children,
  className,
  columnId,
  isActionColumn,
  mobileHeading,
  rowIndex,
  ...props
}: BaseTableBodyCellProps) => (
  <td
    className={cx(
      hasRowStriping ? styles.cell : styles.newRowStyles,
      isActionColumn && styles.actionCell,
      className,
    )}
    data-chameleon-target={`${chameleonTableTitle} Table ${
      chameleonId || columnId
    } Cell`}
    id={`${columnId}-${rowIndex}`}
    {...props}
  >
    {!isActionColumn && mobileHeading && (
      <Text className={styles.heading} wrap="truncate">
        {mobileHeading}
      </Text>
    )}
    <div className={cx(styles.content, isActionColumn && styles.actionContent)}>
      {children}
    </div>
  </td>
);

const TableBodyCell: OverloadedTableBodyCellComponent = <
  TData extends RowData,
  TValue,
>(
  props: BaseTableBodyCellProps | AutomaticTableBodyCellProps<TData, TValue>,
) => {
  if ('cell' in props) {
    const { cell, rowIndex, ...base } = props;
    const column = cell.column;
    const columnHeader = column.columnDef.header;
    const columnMeta = column.columnDef.meta;

    const table = cell.getContext().table;
    const chameleonTableTitle = table.options.meta?.chameleonTableTitle;
    const hasRowStriping = table.options.meta?.hasRowStriping;

    // You already forgot, didn't you?.
    if (chameleonTableTitle == null) {
      throw new Error('Table has no chameleonTableTitle defined!');
    }

    const chameleonId = columnMeta?.chameleonId;
    const className = columnMeta?.className;
    const isActionColumn = columnMeta?.isActionColumn ?? false;
    let mobileHeading = columnMeta?.mobileHeading;

    if (!mobileHeading && typeof columnHeader === 'string') {
      mobileHeading = columnHeader;
    }

    return (
      <BaseTableBodyCell
        hasRowStriping={hasRowStriping}
        chameleonId={chameleonId}
        chameleonTableTitle={chameleonTableTitle}
        className={className}
        columnId={column.id}
        isActionColumn={isActionColumn}
        mobileHeading={mobileHeading}
        rowIndex={rowIndex}
        {...base}
      >
        {flexRender(column.columnDef.cell, cell.getContext())}
      </BaseTableBodyCell>
    );
  }

  return <BaseTableBodyCell {...props} />;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default TableBodyCell;
