import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CustomerReview,
  CustomerReviewReply,
  CustomerReviewReplyFormValues,
  CustomerReviewsResponse,
} from 'types/customer-feedback';
import { Shop } from 'types/shops';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getReviewsQueryKey } from './use-reviews-query';

export const useCreateCustomerFeedbackReplyMutation = (
  shopId: Shop['shopId'],
  review: CustomerReview,
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      values: CustomerReviewReplyFormValues,
    ): Promise<CustomerReviewReply> => {
      const reviewReplyBody = {
        feedbackResponseReply: {
          body: values.reply,
        },
      };

      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/feedback_responses/${review.id}/replies`,
        snakeCaseKeys(reviewReplyBody),
      );

      return camelCaseKeys(response);
    },
    onSuccess: (data) => {
      queryClient.setQueriesData<CustomerReviewsResponse>(
        getReviewsQueryKey(shopId),
        (current) => {
          if (current) {
            const review = current.data.find(
              (it) => it.id === data.feedbackResponseId,
            );

            if (review) {
              review.responses.push(data);
            }
          }

          return current;
        },
      );
    },
  });
};
