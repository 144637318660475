import * as React from "react";
import { forwardRef } from "react";
const SvgFilter = ({
  title,
  titleId,
  ...props
}, ref) => {
  return /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", ref, "aria-labelledby": titleId, ...props }, title === void 0 ? /* @__PURE__ */ React.createElement("title", { id: titleId }, "Filter Icon") : title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("path", { d: "M5 4C5 3.44772 5.44772 3 6 3C6.55228 3 7 3.44772 7 4V20C7 20.5523 6.55228 21 6 21C5.44772 21 5 20.5523 5 20V4Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M11 4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V4Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M17 4C17 3.44772 17.4477 3 18 3C18.5523 3 19 3.44772 19 4V20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20V4Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M8.5 9.5C8.5 10.8807 7.38071 12 6 12C4.61929 12 3.5 10.8807 3.5 9.5C3.5 8.11929 4.61929 7 6 7C7.38071 7 8.5 8.11929 8.5 9.5Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M20.5 9.5C20.5 10.8807 19.3807 12 18 12C16.6193 12 15.5 10.8807 15.5 9.5C15.5 8.11929 16.6193 7 18 7C19.3807 7 20.5 8.11929 20.5 9.5Z", fill: "currentColor" }), /* @__PURE__ */ React.createElement("path", { d: "M14.5 15.5C14.5 16.8807 13.3807 18 12 18C10.6193 18 9.5 16.8807 9.5 15.5C9.5 14.1193 10.6193 13 12 13C13.3807 13 14.5 14.1193 14.5 15.5Z", fill: "currentColor" }));
};
const ForwardRef = forwardRef(SvgFilter);
export default ForwardRef;
