import { useMemo, useState } from 'react';
import {
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';

import ContentTile from 'components/shared/content-tile';
import { PayoutStatusDisplay } from 'components/shared/payout-status-display';
import Table from 'components/shared/table';
import Pagination from 'components/shared/table/pagination';
import { usePayoutsQuery } from 'hooks/payouts/use-payouts-query';
import { useTable } from 'hooks/shared';
import { Payout } from 'types/payouts';
import { Shop } from 'types/shops';
import { toMoneyString } from 'utilities/currency';
import { formatDate } from 'utilities/date-time';

import styles from './styles.module.scss';

const DEFAULT_PER_PAGE = 20;
const DEFAULT_SORT = { id: 'createdAt', desc: true };

type Props = {
  shopId: Shop['shopId'];
  shopTimezone: Shop['timezoneIdentifier'];
};

export const PayoutsTableTile = ({ shopId, shopTimezone }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([DEFAULT_SORT]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PER_PAGE,
  });

  const setPerPage = (perPage: number): void => {
    setPagination({
      pageIndex: 0,
      pageSize: perPage,
    });
  };

  const { data: response, isLoading } = usePayoutsQuery(
    shopId,
    pagination,
    sorting[0] ?? DEFAULT_SORT,
  );

  const data = response?.payouts ?? [];
  const meta = response?.meta;

  const columns = useMemo(() => {
    const helper = createColumnHelper<Payout>();

    return [
      helper.accessor('id', {
        id: 'id',
        header: 'Payout ID',
        enableSorting: true,
      }),
      helper.accessor(
        (row) => formatDate(row.createdAt, 'MM/DD/YY', shopTimezone),
        {
          id: 'createdAt',
          header: 'Date Created',
          enableSorting: true,
        },
      ),
      helper.accessor('sentOutAt', {
        id: 'sentOutAt',
        header: 'Date Paid',
        enableSorting: true,
        cell(ctx) {
          const { sentOutAt } = ctx.row.original;
          return (
            <span>
              {sentOutAt
                ? formatDate(sentOutAt, 'MM/DD/YY', shopTimezone)
                : '-'}
            </span>
          );
        },
      }),
      helper.accessor(
        (row) => {
          if (row.amount) {
            return toMoneyString(row.amount);
          }

          return '-';
        },
        {
          id: 'amount',
          header: 'Amount',
          enableSorting: true,
        },
      ),
      helper.accessor('paymentMethodType', {
        id: 'paymentMethodType',
        header: 'Method',
        // Restaurant API endpoint does not support sorting by
        // payment_method_type.
        enableSorting: false,
      }),
      helper.accessor((row) => row.status, {
        id: 'status',
        header: 'Status',
        enableSorting: true,
        cell(ctx) {
          return <PayoutStatusDisplay status={ctx.row.original.status} />;
        },
      }),
    ];
  }, [shopTimezone]);

  const pageCount = meta?.pages ?? 1;
  const shouldSuppressPagination = !meta || isLoading || pageCount === 1;

  const table = useTable({
    chameleonTableTitle: 'Payouts table',
    columns,
    data,
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    pageCount,
    state: {
      isLoading,
      pagination,
      sorting,
    },
  });

  return (
    <ContentTile>
      <Table className={styles.table} table={table} />
      <Pagination
        className={styles.pagination}
        currentPage={table.getState().pagination.pageIndex + 1}
        isPageControlVisible={!shouldSuppressPagination}
        onChangePage={(page) => table.setPageIndex(page - 1)}
        totalPages={table.getPageCount()}
        perPage={pagination.pageSize}
        onChangePerPage={setPerPage}
      />
    </ContentTile>
  );
};
