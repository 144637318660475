import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterPrinter } from 'types/register-printing';
import { networkToDomainRegisterPrinter } from 'utilities/register-printers';

export const getRegisterPrintingSettingsQueryKey = (
  shopId: string,
): QueryKey => [shopId, 'printSettings'];

export const useRegisterPrintingSettingsQuery = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getRegisterPrintingSettingsQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${shopId}/printer_roles`,
      );

      return response.map(networkToDomainRegisterPrinter) as RegisterPrinter[];
    },
  });
};
