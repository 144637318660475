import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuProductPrintSettingsResponseBody } from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import { getProductQueryKey } from './use-product-query';

export const getProductPrintSettingsQueryKey = (
  shopId: Shop['shopId'],
  productId: MenuProduct['id'],
): QueryKey => [...getProductQueryKey(shopId, productId), 'printSettings'];

export const useProductPrintSettingsQuery = (
  shopId: Shop['shopId'],
  productId: MenuProduct['id'],
  options?: UseQueryOptions<MenuProductPrintSettingsResponseBody>,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getProductPrintSettingsQueryKey(shopId, productId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${shopId}/menu/products/${productId}/print_settings`,
      );
      return camelCaseKeys(response) as MenuProductPrintSettingsResponseBody;
    },
    ...options,
  });
};
