/* eslint-disable @typescript-eslint/no-explicit-any */
import queryString from 'query-string';

import { ShopProfile } from 'types/brand-manager';
import ClosingReason from 'types/closing-reasons';
import { DailyHours } from 'types/daily-hours';
import { DebitCards } from 'types/debit-cards';
import {
  OnlineOrdersRequestParams,
  OnlineOrdersResponse,
} from 'types/financials/online-orders';
import { OpasFilters, ProductSalesMetricsResponse } from 'types/opas';
import { OpenForToday } from 'types/open-for-today';
import { OrderStatement, OrderStatementResponse } from 'types/order-statement';
import {
  TipsAndEarningsRequestParams,
  TipsAndEarningsResponseBody,
} from 'types/orders';
import { PartnerSuccessManagerInfo } from 'types/partner-success-manager';
import { RegisterLayout } from 'types/register-layout';
import { Pause, Schedule } from 'types/shop-hours';
import { Shop } from 'types/shops';
import { SiteEditorSettings } from 'types/site-editor';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { ApiRequestError } from './helpers';

// This typescript rule is disabled to allow for type inferring of the API. This means in typescript files
// that make use of the useAPI hook, if the specific api function is typed the returned data will also be typed.
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const create = (
  authenticatedFetch: {
    get: any;
    put: any;
    patch: any;
    post: any;
    del: any;
    delete: any;
    postFile: any;
  },
  hostnames: { restaurantApi: any },
) => ({
  /* eslint-enable @typescript-eslint/explicit-module-boundary-types */
  getDailyHours: (shopId: string): DailyHours =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/daily_hours`,
      )
      .then((data: any) => camelCaseKeys(data)),

  updateDailyHours: (shopId: any, params: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/daily_hours`,
        params,
      )
      .then(({ hours }: any) => hours),

  getClosings: (shopId: any) =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/closings`,
      )
      .then(({ data }: any) => {
        // Formatting the closing data to allow easier
        // interactions with the closureReason prop
        // at the component level e.g. sorting
        const closings = data.map((closing: any) => ({
          ...closing,
          closureReason: closing.closure_reason.id,
          closureReasonName: closing.closure_reason.name,
        }));
        return camelCaseKeys(closings);
      }),

  deleteClosing: (shopId: any, closingId: any) =>
    authenticatedFetch.del(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/closings/${closingId}`,
    ),

  createClosing: (shopId: any, closingParams: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/closings`,
        closingParams,
      )
      .then(({ data }: any) => camelCaseKeys(data)),

  updateClosing: (shopId: any, closingId: any, closingParams: any) =>
    authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/closings/${closingId}`,
        closingParams,
      )
      .then(({ data }: any) => camelCaseKeys(data)),

  getTipsAndEarnings: (
    shopId: Shop['shopId'],
    params: TipsAndEarningsRequestParams,
  ): Promise<TipsAndEarningsResponseBody> => {
    const queryStr = `?${queryString.stringify(snakeCaseKeys(params))}`;

    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/orders/tips_and_earnings${queryStr}`,
      )
      .then(camelCaseKeys);
  },

  getOrders: async (
    shopId: string,
    params: OnlineOrdersRequestParams,
  ): Promise<OnlineOrdersResponse> => {
    const queryStr = `?${queryString.stringify(snakeCaseKeys(params))}`;

    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/orders${queryStr}`,
      {},
      true,
      true,
      true,
    );

    response.body.meta = {
      ...response.body.meta,
      pagination: {
        pages: Number(response.headers.get('X-Total-Pages') ?? 0),
        total: Number(response.headers.get('X-Total') ?? 0),
      },
    };

    return camelCaseKeys(response.body);
  },

  getOrderStatement: async (
    shopId: number,
    orderId: string,
  ): Promise<OrderStatementResponse> => {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/orders/${orderId}/statement`,
    );

    return camelCaseKeys(response);
  },

  // Even though the confirmation metric tile has been removed, we intend to use
  // this endpoint again in the future.
  // https://app.clubhouse.io/slicelife/story/220935/remove-confirmation-metric-tile-and-redux-store
  getShopConfirmationMetrics: (shopId: any, intervalEndDate: any) => {
    const queryStr = queryString.stringify({
      interval_end_date: intervalEndDate,
    });
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/confirmation_time?${queryStr}`,
      )
      .then((response: { confirmation_metrics: any }) => ({
        // The corresponding saga wants to index the response by shopId, but a singular nested
        // object is returned. Putting the returned metrics into an array satisifies the saga
        // expectations for now.
        response: [camelCaseKeys(response.confirmation_metrics)],
      }))
      .catch((error: any) => ({ error }));
  },

  getShopOrderSummary: (
    shopId: any,
    startDate: any,
    endDate: any,
    shopTimezone: any,
  ) => {
    const queryStr = queryString.stringify({
      end: endDate,
      start: startDate,
      timezone: shopTimezone,
    });
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/orders/by/day?${queryStr}`,
      )
      .then(camelCaseKeys);
  },

  getShopOpenForToday: (
    shopId: any,
  ): {
    response: OpenForToday;
  } =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/open_for_today`,
      )
      .then((response: any) => camelCaseKeys(response.data)),

  getShopSchedule: (shopId: any): Promise<Schedule> =>
    authenticatedFetch
      .get(`${hostnames.restaurantApi}/api/v1/shops/${shopId}/schedule`)
      .then(camelCaseKeys),

  generateShopSchedule: (
    shopId: string,
    generateScheduleParams: any,
  ): Promise<Schedule> =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/v1/shops/${shopId}/schedule/generate`,
        generateScheduleParams,
      )
      .then(camelCaseKeys),

  updateShopOpenForToday: (shopId: any, openForTodayParams: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/open_for_today`,
        openForTodayParams,
      )
      .then(({ data }: any) => ({ response: camelCaseKeys(data) }))
      .catch((error: any) => ({ error })),

  getShopOpenings: (shopId: any) =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/openings`,
      )
      .then(camelCaseKeys),

  createShopOpening: ({ shopId, opening }: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/openings`,
        { opening },
      )
      .then(camelCaseKeys),

  deleteShopOpening: ({ shopId, openingId, openFor, dayOfWeek }: any) =>
    authenticatedFetch
      .del(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/openings/${openingId}`,
      )
      .then(() => ({
        shopId,
        openingId,
        openFor,
        dayOfWeek,
        type: 'delete',
      })),

  updateShopOpening: ({ shopId, openingId, opening }: any) =>
    authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/openings/${openingId}`,
        { opening },
      )
      .then(camelCaseKeys),

  async getShopStatements(shopId: Shop['shopId']): Promise<OrderStatement[]> {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/statements`,
    );

    return camelCaseKeys(response.statements);
  },

  async getDebitCards(shopId: number): Promise<DebitCards[]> {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/debit_cards`,
    );

    return camelCaseKeys(response.data);
  },

  async getStatement(
    shopId: Shop['shopId'],
    statementId: OrderStatement['id'],
  ): Promise<OrderStatementResponse> {
    const response = await authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/statements/${statementId}`,
    );

    return camelCaseKeys(response.statement);
  },

  getCurrentUser: () =>
    authenticatedFetch
      .get(`${hostnames.restaurantApi}/api/management/v1/me`)
      .then(camelCaseKeys),

  putPasswordReset: (password: any, passwordResetToken: any) =>
    authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/password_resets`,
        {
          password,
          password_reset_token: passwordResetToken,
        },
        {},
        true,
        false,
        true,
      )
      .then(camelCaseKeys),

  postSendPasswordResetEmail: (email: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/password_resets`,
        { email },
        {},
        true,
        false,
        true,
      )
      .then(camelCaseKeys),

  getPosIntegration: (shopId: string) =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/pos_integration`,
      )
      .then(camelCaseKeys)
      .catch((error: ApiRequestError) => {
        // A 404 here is fine, it just means the shop has no integration.
        if (error.status === 404) {
          return null;
        }

        throw error;
      }),

  getPosIntegrationRedirect: (shopId: string, provider: string) =>
    authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/pos_integration/${provider}/authorize`,
    ),

  postPosIntegrationDisconnectAuth: (shopId: string, provider: string) =>
    authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/pos_integration/${provider}/disconnect`,
    ),

  postPosIntegrationRevokeAuth: (shopId: string, provider: string) =>
    authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/pos_integration/${provider}/revoke`,
    ),

  fetchOrderTotalsByShippingTypeMetrics: (
    shopId: any,
    dateParams: Record<string, any>,
  ) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/order_totals_by_shipping_types?${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: { data: any }) => response.data),

  async fetchCustomerTotalsMetrics(
    shopId: string,
    dateParams: Record<string, any>,
  ): Promise<any> {
    const response = await authenticatedFetch.get(
      `${
        hostnames.restaurantApi
      }/api/management/v2/shops/${shopId}/metrics/customers?${queryString.stringify(
        snakeCaseKeys(dateParams),
      )}`,
    );

    return camelCaseKeys(response);
  },

  fetchCustomersByWeekMetrics: (shopId: any, dateParams: Record<string, any>) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/customers/by/week?iterations=4&${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: any) => camelCaseKeys(response)),

  fetchProductSalesMetrics: (shopId: any, dateParams: Record<string, any>) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/product_sales?${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: { data: any }) => response.data),

  // OPAS uses a post request with filters supplied in the request body for getting product sales metrics in v2
  // of the OPAS endpoint.
  postProductSalesMetrics: (
    shopId: string,
    filters: OpasFilters,
  ): Promise<ProductSalesMetricsResponse> =>
    authenticatedFetch.post(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/metrics/product_sales`,
      filters,
    ),

  fetchSalesByDayMetrics: (shopId: any, dateParams: Record<string, any>) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/sales_by_days?${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: { data: any }) => response.data),

  fetchMostValuableCustomers: (shopId: any, dateParams: Record<string, any>) =>
    authenticatedFetch
      .get(
        `${
          hostnames.restaurantApi
        }/api/management/v1/shops/${shopId}/metrics/most_valuable_customers?${queryString.stringify(
          snakeCaseKeys({
            ...dateParams,
            shouldUseOpas: true,
          }),
        )}`,
      )
      .then((response: { data: any }) => camelCaseKeys(response.data)),

  // Register layout:
  getRegisterLayout: (shopId: string): Promise<RegisterLayout> =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/menu`,
      )
      .then(camelCaseKeys),

  updateRegisterLayout: (
    shopId: string,
    layout: RegisterLayout,
  ): Promise<RegisterLayout> =>
    authenticatedFetch
      .put(
        `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/menu/layouts/${layout.id}`,
        snakeCaseKeys(layout),
      )
      .then(camelCaseKeys),

  createDebitCard: (shopId: any, debitCardToken: any) =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/debit_cards`,
        debitCardToken,
      )
      .then(camelCaseKeys),

  sendEmail: (shopId: string, subject: string, to: string) =>
    authenticatedFetch
      .post(`${hostnames.restaurantApi}/api/management/v1/email/send_email`, {
        subject,
        to,
        shop_id: shopId,
      })
      .then(camelCaseKeys),

  getClosingReasons: (scope: string | null): Promise<ClosingReason[]> =>
    authenticatedFetch.get(
      `${hostnames.restaurantApi}/api/management/v1/closure_reasons${
        scope ? `?scope=${scope}` : ''
      }`,
    ),

  // Pausings

  getPausings: (shopId: string): Promise<Pause[]> =>
    authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/pausings`,
      )
      .then(({ data }: { data: Pause[] }) => camelCaseKeys(data)),

  createPause: (shopId: string, newPause: any): Promise<Pause> =>
    authenticatedFetch
      .post(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/pausings`,
        snakeCaseKeys(newPause),
      )
      .then(({ data }: { data: Pause }) => camelCaseKeys(data)),

  updatePause: (
    shopId: string,
    pausingId: string,
    endTime: string,
  ): Promise<Pause> =>
    authenticatedFetch
      .patch(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/pausings/${pausingId}`,
        snakeCaseKeys({ endTime }),
      )
      .then(({ data }: { data: Pause }) => camelCaseKeys(data)),

  deletePause: (shopId: string, pausingId: string) =>
    authenticatedFetch.del(
      `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/pausings/${pausingId}`,
    ),

  // Brand manager
  getShopProfile: (shopId: string): ShopProfile => {
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/brand_manager/shop_profile/${shopId}`,
      )
      .then((response: ShopProfile): ShopProfile => {
        return response;
      });
  },

  deleteRegisterUser: (userId: string, shopId: string) => {
    console.log(
      `I would have sent a delete request to ${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users/${userId}`,
    );
    // TODO - When backend endpoint is in place uncomment the actual api call below
    // return authenticatedFetch.del(
    //   `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users/${userId}`,
    // );
    return Promise.resolve(userId);
  },

  getDirectWebSettings: (shopId: string): Promise<SiteEditorSettings> => {
    return authenticatedFetch
      .get(
        `${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/direct_web_settings`,
      )
      .then(camelCaseKeys);
  },

  getPartnerSuccessManagerInfo: (
    shopId: string,
  ): Promise<PartnerSuccessManagerInfo> => {
    return authenticatedFetch
      .get(`${hostnames.restaurantApi}/api/management/v1/shops/${shopId}/admin`)
      .then(camelCaseKeys);
  },

  async getAchOnboardingLink(shopId: Shop['shopId']) {
    try {
      const response = await authenticatedFetch.get(
        `${hostnames.restaurantApi}/api/management/v2/shops/${shopId}/onboarding_link`,
      );

      return camelCaseKeys(response);
    } catch (error: unknown) {
      // This endpoint will return with an error for shops not configured for adyen
      // which currently we cannot determine before making the request used to determine
      // the ach banner visibility
      return {
        redirectUrl: '',
        showOnboardingLink: false,
      };
    }
  },
});

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default create;
