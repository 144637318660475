import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  CustomerOrderProfile,
  PagedCustomerProfileOrder,
} from 'types/customers';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

export const getCustomersOrdersQueryKey = (
  shopId: number,
  customerUuid: string,
): QueryKey => [shopId, customerUuid, 'getCustomersOrders'];

export const useCustomersOrdersQuery = (
  shopId: number,
  customerUuid: string,
  options?: UseInfiniteQueryOptions<PagedCustomerProfileOrder>,
) => {
  const { authenticatedFetch } = useApi();

  return useInfiniteQuery({
    queryKey: getCustomersOrdersQueryKey(shopId, customerUuid),
    queryFn: async () => {
      let query = '';
      const pageParams = {
        page: 1,
        perPage: 5,
      };

      query = `?${queryString.stringify(snakeCaseKeys(pageParams))}`;

      const response = await authenticatedFetch.get(
        `api/v1/shops/${shopId}/marketing/customers/${customerUuid}${query}`,
      );

      const transformedResponse: CustomerOrderProfile = camelCaseKeys(response);

      const pagedData = {
        data: transformedResponse.orders,
        nextPage: pageParams
          ? pageParams.page * pageParams.perPage <
            transformedResponse.orderCount
            ? pageParams.page + 1
            : undefined
          : 2,
      };

      return pagedData;
    },
    ...options,
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });
};
