import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CustomerList,
  CustomerSort,
  CustomersResponse,
  CustomerType,
  DateRange,
  Interval,
} from 'types/customers';
import { createCustomerFilter, getInterval } from 'utilities/customers';
import { camelCaseKeys } from 'utilities/objects';

type UseTop10CustomersQueryOptions = Omit<
  UseQueryOptions<CustomersResponse>,
  'queryKey' | 'queryFn'
>;

type Props = {
  customerList: CustomerList;
  customerType: CustomerType;
  dateRange: DateRange;
  shopId: string;
  timezone: string;
  options?: UseTop10CustomersQueryOptions;
  page: number;
  perPage: number;
  sort: CustomerSort;
  customerUuids?: string[];
  userNameSearch?: string;
  top10Customers?: boolean;
};

export const getCustomersQueryKey = ({
  customerList,
  customerType,
  dateRange,
  page,
  perPage,
  shopId,
  sort,
  customerUuids,
  userNameSearch,
}: Omit<Props, 'timezone' | 'options'>): QueryKey => [
  shopId,
  'customers-v1',
  customerList,
  dateRange,
  customerType,
  page,
  perPage,
  sort,
  customerUuids,
  userNameSearch,
];

export const getTop10CustomersQueryKey = ({
  customerType,
  dateRange,
  customerList,
  shopId,
  page,
  perPage,
  sort,
}: Omit<Props, 'timezone' | 'options'>): QueryKey => [
  shopId,
  'top10Customers',
  dateRange,
  customerType,
  customerList,
  page,
  perPage,
  sort,
];

const useCustomersQuery = ({
  customerList = CustomerList.All,
  customerType,
  customerUuids = [],
  dateRange,
  page,
  perPage,
  shopId,
  timezone,
  options,
  userNameSearch,
  top10Customers,
  sort,
}: Props) => {
  const { authenticatedFetch } = useApi();

  const getCustomers = async (
    shopId: string,
    page: number,
    perPage: number,
    sort: CustomerSort,
    interval: Interval,
    customerType: CustomerType,
    customerList: CustomerList,
    customerUuids: string[],
    userNameSearch?: string,
  ) => {
    const filters = [];

    // Filter by customer list
    if (customerList === CustomerList.New) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'new'),
      );
    } else if (customerList === CustomerList.Loyal) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'loyal'),
      );
    } else if (customerList === CustomerList.Repeat) {
      filters.push(
        createCustomerFilter('customer_loyalty_status', 'is', 'repeat'),
      );
    } else if (customerList === CustomerList.Delivery) {
      filters.push(createCustomerFilter('shipping_type', 'is', 'delivery'));
    } else if (customerList === CustomerList.Pickup) {
      filters.push(createCustomerFilter('shipping_type', 'is', 'pickup'));
    } else if (
      customerList === CustomerList.MostFrequent ||
      customerList === CustomerList.MostValuable
    ) {
      filters.push(createCustomerFilter('customer_uuid', 'in', customerUuids));
    }

    // Filter by order type (online or register)
    if (customerType !== CustomerType.All) {
      filters.push(createCustomerFilter('order_type', 'is', customerType));
    }

    // User name search filter
    if (userNameSearch && userNameSearch.length !== 0) {
      filters.push(
        createCustomerFilter('customer_name', 'like', userNameSearch),
      );
    }

    const response = await authenticatedFetch.post(
      `api/v1/shops/${shopId}/marketing/customers`,
      {
        filters: filters,
        interval,
        pagination: {
          page: page,
          per_page: perPage,
        },
        sort: {
          by: sort.by,
          direction: sort.direction,
        },
      },
    );

    return camelCaseKeys(response) as CustomersResponse;
  };

  return useQuery({
    queryKey: !top10Customers
      ? getCustomersQueryKey({
          customerList,
          customerType,
          dateRange,
          page,
          perPage,
          shopId,
          sort,
          userNameSearch,
        })
      : getTop10CustomersQueryKey({
          customerType,
          dateRange,
          customerList,
          shopId,
          page,
          perPage,
          sort,
        }),
    queryFn: () =>
      getCustomers(
        shopId,
        page,
        perPage,
        sort,
        getInterval(dateRange, timezone),
        customerType,
        customerList,
        customerUuids,
        userNameSearch,
      ),
    ...options,
  });
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default useCustomersQuery;
