import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuProductResponseBody } from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { Shop } from 'types/shops';

import { getMenuProductsQueryKey } from './use-menu-products-query';
import { getMenuQueryKey } from './use-menu-query';
import { getProductQueryKey } from './use-product-query';

type Values = {
  productId: MenuProduct['id'];
  shopId: Shop['shopId'];
};

export const useDeleteProductMutation = () => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ productId, shopId }: Values) => {
      const response = await authenticatedFetch.delete(
        `api/management/v2/shops/${shopId}/menus/products/${productId}`,
      );

      return response as MenuProductResponseBody;
    },
    onSuccess: (_, { productId, shopId }) => {
      // Also removes the query for the register printer settings associated
      // with this product.
      queryClient.removeQueries(getProductQueryKey(shopId, productId));
      queryClient.invalidateQueries(getMenuQueryKey(shopId));
      queryClient.invalidateQueries(getMenuProductsQueryKey(shopId));
    },
  });
};
