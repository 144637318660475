import ContentTile from 'components/shared/content-tile';
import { useShopPublishersQuery } from 'hooks/brand-manager';
import { BrandManagerDateRange } from 'types/brand-manager/date-picker';
import { getTotalRevenueAndOrders } from 'utilities/brand-manager';

import Metric from './metric';

import styles from './styles.module.scss';

type Props = {
  dateRange: BrandManagerDateRange;
  shopId: string;
};

const ReturnOnInvestment = ({ dateRange, shopId }: Props) => {
  const { isLoading, data } = useShopPublishersQuery(Number(shopId));

  const { orders, revenue } = getTotalRevenueAndOrders(data, dateRange);

  return (
    <ContentTile
      className={styles.tile}
      isLoading={isLoading}
      headerClassName={styles.title}
      headerContent="Online Brand Management Return"
    >
      <div className={styles.metricsContainer}>
        <Metric
          className={styles.revenueTile}
          label="Revenue"
          value={revenue}
        />
        <div className={styles.verticalDivider} />
        <Metric
          className={styles.ordersTile}
          label="Total Orders"
          value={orders}
        />
      </div>
    </ContentTile>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ReturnOnInvestment;
