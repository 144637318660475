import { useEffect, useMemo, useState } from 'react';
import { ColumnFiltersState } from '@tanstack/react-table';

import ContentTile from 'components/shared/content-tile';
import { SearchBar } from 'components/shared/search-bar';
import { useProductsPageState } from 'hooks/menu';
import useAnalytics from 'hooks/use-analytics';
import { MenuProductsProduct, MenuProductsResponseBody } from 'types/menu/api';
import { Shop } from 'types/shops';
import { getShopHasFullRegister } from 'utilities/shops';

import { ActionBar } from './action-bar';
import { CategoryFilters } from './category-filters';
import { useBulkEditContext } from './context';
import { CustomOutOfStockModal } from './custom-out-of-stock-modal';
import { FilterButton } from './filter-button';
import { FiltersModal } from './filters-modal';
import { ProductsTable } from './table';

import styles from './styles.module.scss';

type Props = {
  menu: MenuProductsResponseBody;
  shop: Shop;
};

export const MenuProducts = ({ menu, shop }: Props) => {
  const { trackSearchedMenuItems } = useAnalytics();

  const { search, setSearch } = useProductsPageState();
  const { selectedProductIds, clearSelectedProductIds } = useBulkEditContext();
  const [categoryFilters, setCategoryFilters] = useState<ColumnFiltersState>(
    [],
  );

  const [isCustomOutOfStockModalOpen, setIsCustomOutOfStockModalOpen] =
    useState(false);

  const toggleCustomOutOfStockModal = () => {
    setIsCustomOutOfStockModalOpen(!isCustomOutOfStockModalOpen);
  };

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

  const closeFiltersModal = () => {
    setIsFiltersModalOpen(false);
  };

  const openFiltersModal = () => {
    setIsFiltersModalOpen(true);
  };

  const selectedProductNames: string[] = useMemo(() => {
    const names: string[] = [];

    for (const product of menu.products) {
      if (selectedProductIds.has(product.id)) {
        names.push(product.name);
      }
    }

    return names;
  }, [menu.products, selectedProductIds]);

  const isRegister = getShopHasFullRegister(shop) ?? false;

  const filteredProducts = useMemo(() => {
    if (search) {
      const target = search.toLowerCase();
      const products: MenuProductsProduct[] = menu.products.filter(
        (product) =>
          product.name?.toLowerCase().includes(target) ||
          product.description?.toLowerCase().includes(target),
      );

      trackSearchedMenuItems({
        countResults: products.length,
        isRegister: isRegister,
        page: 'menu items',
        searchText: search,
        shopId: shop.shopId,
      });

      return products;
    }

    return menu.products;
  }, [isRegister, menu.products, search, shop.shopId, trackSearchedMenuItems]);

  useEffect(() => {
    clearSelectedProductIds();
  }, [clearSelectedProductIds, search, categoryFilters]);

  const hasEmptySearchResults = filteredProducts.length === 0 && search;
  const totalItems = menu.products.length ?? 0;

  return (
    <ContentTile
      dataTestId="menuItemsPageContentTile"
      className={styles.productContainer}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.search}>
          <SearchBar
            initialSearchValue={search}
            onChange={setSearch}
            placeholderText={`Search ${totalItems} items`}
          />
          <div className={styles.itemInfo}>
            <FilterButton
              icon="filter"
              onPress={openFiltersModal}
              hasActiveFilters={false}
            >
              Filters
            </FilterButton>
          </div>
        </div>
        <CategoryFilters
          categories={menu.categories}
          categoryFilters={categoryFilters}
          setCategoryFilters={setCategoryFilters}
        />
      </div>

      {hasEmptySearchResults ? (
        <div className={styles.noSearchResultsWrapper}>
          <div className={styles.noSearchResultsTitle}>
            {"Sorry, we couldn't find what you were looking for."}
          </div>
          <div className={styles.noSearchResultsBody}>
            No results for &quot;{search}&quot;. Check your spelling and try
            again, or search for something different.
          </div>
        </div>
      ) : (
        <ProductsTable
          products={filteredProducts}
          categories={menu.categories}
          shop={shop}
          categoryFilters={categoryFilters}
          setCategoryFilters={setCategoryFilters}
        />
      )}
      <ActionBar
        onPressCustom={toggleCustomOutOfStockModal}
        shop={shop}
        selectedProductNames={selectedProductNames}
      />
      <CustomOutOfStockModal
        closeModal={toggleCustomOutOfStockModal}
        isOpen={isCustomOutOfStockModalOpen}
        shop={shop}
      />
      <FiltersModal
        isOpen={isFiltersModalOpen}
        closeModal={closeFiltersModal}
      />
    </ContentTile>
  );
};
