import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuCategoryResponseBody } from 'types/menu/api';
import { MenuCategory } from 'types/menu/category';
import { Shop } from 'types/shops';
import { sortRelationships } from 'utilities/menu';

import { getMenuQueryKey } from './use-menu-query';

export const getCategoryQueryKey = (
  shopId: Shop['shopId'],
  categoryId: MenuCategory['id'],
): QueryKey => [...getMenuQueryKey(String(shopId)), 'categories', categoryId];

export const useCategoryQuery = (
  shopId: Shop['shopId'],
  categoryId: MenuCategory['id'],
  options?: UseQueryOptions<MenuCategoryResponseBody>,
) => {
  const { authenticatedFetch } = useApi();

  // This endpoint does not provide the products in the category.
  return useQuery({
    queryKey: getCategoryQueryKey(shopId, categoryId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/menus/categories/${categoryId}`,
      );
      return sortRelationships(response as MenuCategoryResponseBody);
    },
    ...options,
  });
};
