import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { DndContext, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useQueryClient } from '@tanstack/react-query';

import ContentTile from 'components/shared/content-tile';
import SortableListItem from 'components/shared/sortable-list-item';
import SortableOverlay from 'components/shared/sortable-overlay';
import { getMenuQueryKey, useMenuQuery } from 'hooks/menu';
import { useCategorySortMutation } from 'hooks/menu/use-category-sort-mutation';
import useAnalytics from 'hooks/use-analytics';
import useDebounce from 'hooks/use-debounce';
import { showUnexpectedErrorToast } from 'utilities/forms';
import { indexBy } from 'utilities/lists';

import MenuLayoutCategory from './category';
import MenuInfoTile from './info-tile';

import styles from './styles.module.scss';

type Props = {
  shopId: string;
};

const MenuLayout = ({ shopId }: Props) => {
  const queryClient = useQueryClient();
  const { trackMenuSortUpdated } = useAnalytics();

  const { data: menu, isLoading: isMenuLoading } = useMenuQuery(
    shopId,
    'online',
  );

  // We will set the query data to the revised menu once we are sure that no
  // more patch requests are in flight.
  const revisedMenuRef = useRef(menu);

  useEffect(() => {
    revisedMenuRef.current = menu;
  }, [menu]);

  // The IDs of the categories that have been sorted, but have not performed a
  // mutation. If this set is empty, we can update the query.
  const dragsRef = useRef(new Set<number>());

  const { categoriesById, productsById } = useMemo(
    () => ({
      categoriesById: indexBy(menu?.relationships.categories ?? [], 'id'),
      productsById: indexBy(menu?.relationships.products ?? [], 'id'),
    }),
    [menu],
  );

  const { mutate: handleCategorySort } = useCategorySortMutation(shopId);

  const [sortedIds, setSortedIds] = useState(menu?.menu.categoryIds ?? []);
  const debouncedSortedIds = useDebounce(sortedIds, 1000);

  useEffect(() => setSortedIds(menu?.menu.categoryIds ?? []), [menu]);

  const productCount = useMemo(
    () =>
      menu?.relationships.categories?.reduce(
        (sum, it) => sum + (it.productIds?.length ?? 0),
        0,
      ) ?? 0,
    [menu],
  );

  useEffect(() => {
    if (dragsRef.current.has(-1)) {
      dragsRef.current.delete(-1);
      handleCategorySort(debouncedSortedIds, {
        onSettled: (data, error) => {
          toast.dismiss();

          if (error) {
            showUnexpectedErrorToast();
            return;
          }

          toast.success('Categories sorted!');
          trackMenuSortUpdated(shopId, true);

          if (data && revisedMenuRef.current) {
            revisedMenuRef.current = {
              ...revisedMenuRef.current,
              menu: data.menu,
            };
          }

          if (dragsRef.current.size === 0) {
            queryClient.setQueryData(
              getMenuQueryKey(shopId),
              revisedMenuRef.current,
            );
          }
        },
      });
    }
  }, [
    debouncedSortedIds,
    handleCategorySort,
    queryClient,
    shopId,
    trackMenuSortUpdated,
  ]);

  const findIndex = (id: number) => sortedIds.findIndex((it) => it === id);

  // No category will have a negative ID, so use -1 for none.
  const [activeCategoryId, setActiveCategoryId] = useState(-1);

  const activeCategory = categoriesById[activeCategoryId];

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveCategoryId(Number(active?.id ?? -1));
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setActiveCategoryId(-1);

    if (over == null) {
      return;
    }

    const activeIndex = findIndex(Number(active.id));
    const overIndex = findIndex(Number(over.id));

    if (activeIndex === overIndex) {
      return;
    }

    // No category will have a negative ID, so use -1 for all categories.
    dragsRef.current.add(-1);
    setSortedIds((current) => arrayMove(current, activeIndex, overIndex));
  };

  const handleDragCancel = () => {
    setActiveCategoryId(-1);
  };

  return (
    <>
      <MenuInfoTile
        categoryCount={sortedIds.length}
        isLoading={isMenuLoading}
        productCount={productCount}
      />
      <ContentTile isLoading={isMenuLoading}>
        <DndContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={sortedIds.map(String)}
            strategy={verticalListSortingStrategy}
          >
            <ol className={styles.categories}>
              {sortedIds.map((it) => {
                const category = categoriesById[it];
                return category ? (
                  <SortableListItem key={it} id={it}>
                    <MenuLayoutCategory
                      dragsRef={dragsRef}
                      id={it}
                      name={category.name}
                      productIds={category.productIds ?? []}
                      productsById={productsById}
                      revisedMenuRef={revisedMenuRef}
                      shopId={shopId}
                    />
                  </SortableListItem>
                ) : null;
              })}
            </ol>
          </SortableContext>
          <SortableOverlay withPortal>
            {activeCategory ? (
              <MenuLayoutCategory
                dragsRef={dragsRef}
                id={Number(activeCategory.id)}
                name={activeCategory.name}
                productIds={activeCategory.productIds ?? []}
                productsById={productsById}
                revisedMenuRef={revisedMenuRef}
                shopId={shopId}
              />
            ) : null}
          </SortableOverlay>
        </DndContext>
      </ContentTile>
    </>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuLayout;
