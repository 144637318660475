import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { CustomerImportsSchema } from 'types/customers/profile/customer-profile-import';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

export const getCustomerImportsQueryKey = (
  shopId: Shop['shopId'],
): QueryKey => [shopId, 'getCustomerImports'];

export const useCustomerImportsQuery = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/customer_uploads`,
      );

      return CustomerImportsSchema.parse(camelCaseKeys(response));
    },
    queryKey: getCustomerImportsQueryKey(shopId),
  });
};
