import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterUser, UserFormValues } from 'types/register-users';
import { upsertBy } from 'utilities/lists';
import { snakeCaseKeys } from 'utilities/objects';
import { networkRegisterUserToRegisterUser } from 'utilities/register-users';

import { getRegisterUsersQueryKey } from '.';

export const useSaveRegisterUserMutation = (
  isNew: boolean,
  shopId: string,
  userId: string,
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: UserFormValues) => {
      const data = {
        // We don't use the nullish coalescing operator throughout as when the inputs are empty we want to send
        // null to the backend for the 'only one of email or phone values needed' checks and with empty strings
        // the nullish coalescing would cause the empty string to be sent
        email: values.email ? values.email : null,
        name: values.name,
        permissionGroups: values.permissionGroups,
        phoneNumber: values.phone ? values.phone : null,
        pin: null,
      };
      const response = isNew
        ? await authenticatedFetch.post(
            `api/management/v1/ros/shops/${shopId}/register_users`,
            snakeCaseKeys(data),
          )
        : await authenticatedFetch.put(
            `api/management/v1/ros/shops/${shopId}/register_users/${userId}`,
            snakeCaseKeys(data),
          );

      return networkRegisterUserToRegisterUser(response) as RegisterUser;
    },
    onSuccess: (response: RegisterUser) => {
      queryClient.setQueryData<RegisterUser[]>(
        getRegisterUsersQueryKey(shopId),
        (old = []) => upsertBy(old, response, 'id'),
      );
    },
  });
};
