import { CalendarDate } from '@internationalized/date';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import { TaxReport } from 'types/financials';
import { Shop } from 'types/shops/shop';
import { camelCaseKeys } from 'utilities/objects';

import { getTaxReportsQueryKey } from './use-tax-reports-query';

type Values = {
  dates: RangeValue<CalendarDate>;
};

export const useCreateTaxReportMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dates }: Values) => {
      const params = {
        start: dates.start.toString(),
        end: dates.end.toString(),
      };
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/tax_reports`,
        params,
      );

      return camelCaseKeys(response) as TaxReport;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getTaxReportsQueryKey(shopId));
    },
  });
};
