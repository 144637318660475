import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ApiRequestError } from 'providers/api/helpers';
import { Shop, ShopTraits } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import useApi from '../use-api';

import { getShopQueryKey } from '.';

type UseShoptraitsQueryOptions = Omit<
  UseQueryOptions<ShopTraits>,
  'queryKey' | 'queryFn'
>;

export const getShopTraitsQueryKey = (shopId: Shop['shopId']): QueryKey => [
  ...getShopQueryKey(shopId),
  'traits',
];

export const useShopTraitsQuery = (
  shopId: Shop['shopId'],
  options?: UseShoptraitsQueryOptions,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getShopTraitsQueryKey(shopId),
    queryFn: async () => {
      try {
        const response = await authenticatedFetch.get(
          `api/management/v1/shops/${shopId}/traits`,
        );

        return camelCaseKeys(response.traits) as ShopTraits;
      } catch (error) {
        // A 404 here is fine, it just means the shop has no integration.
        if (error instanceof ApiRequestError && error.status === 404) {
          return {};
        }

        throw error;
      }
    },
    ...options,
  });
};
