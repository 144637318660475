import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { OnChangeFn, PaginationState } from '@tanstack/react-table';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import { GetPermissionsLogResponse, PermissionLog } from 'types/register-users';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

type UsePermissionsLog = {
  isLoading: boolean;
  logsRowData: PermissionLog[];
  numLogEntries: number;
  pageCount: number;
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
};

const usePermissionsLog = (shopId: string): UsePermissionsLog => {
  const { authenticatedFetch } = useApi();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isLoading } = useQuery({
    queryKey: [shopId, 'permissionsLog', pagination],
    queryFn: async () => {
      const params = {
        page: pagination.pageIndex + 1,
        perPage: pagination.pageSize,
      };

      const query = queryString.stringify(snakeCaseKeys(params));

      const response = await authenticatedFetch.get(
        `api/management/v1/ros/shops/${shopId}/audit_logs?${query}`,
      );

      return camelCaseKeys(response) as GetPermissionsLogResponse;
    },
  });

  return {
    isLoading,
    logsRowData: data?.data ?? [],
    numLogEntries: data?.metadata?.total ?? 0,
    pageCount: data?.metadata?.pages ?? 1,
    pagination,
    setPagination,
  };
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default usePermissionsLog;
