import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import {
  MenuProductPrintSettingsRequestBody,
  MenuProductPrintSettingsResponseBody,
  MenuProductRequestBody,
  MenuProductResponseBody,
} from 'types/menu/api';
import { MenuProduct } from 'types/menu/product';
import { Shop } from 'types/shops';
import {
  createPrinterSettingsWithFormData,
  createProductWithFormData,
} from 'utilities/menu/product';
import { camelCaseKeys } from 'utilities/objects';

import { getMenuProductsQueryKey } from './use-menu-products-query';
import { getMenuQueryKey } from './use-menu-query';
import { getProductPrintSettingsQueryKey } from './use-product-print-settings-query';

type ProductFormValues = {
  id: MenuProduct['id'] | string;
};

export const useSaveProductMutation = (
  shopId: Shop['shopId'],
  isKitchenNameEnabled: boolean,
): UseMutationResult<
  MenuProductResponseBody,
  ApiRequestError,
  ProductFormValues
> => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  const postProduct = async (
    shopId: Shop['shopId'],
    data: MenuProductRequestBody,
  ): Promise<MenuProductResponseBody> =>
    await authenticatedFetch.post(
      `api/management/v2/shops/${shopId}/menus/products`,
      data,
    );

  const putProduct = async (
    shopId: Shop['shopId'],
    data: MenuProductRequestBody,
  ): Promise<MenuProductResponseBody> =>
    await authenticatedFetch.put(
      `api/management/v2/shops/${shopId}/menus/products/${data.product.id}`,
      data,
    );

  const putProductPrinterSettings = async (
    shopId: Shop['shopId'],
    productId: MenuProduct['id'],
    printerSettings: MenuProductPrintSettingsRequestBody,
  ): Promise<MenuProductPrintSettingsResponseBody> =>
    await authenticatedFetch
      .put(
        `api/management/v1/ros/shops/${shopId}/menu/products/${productId}/print_settings`,
        printerSettings,
      )
      .then(camelCaseKeys);

  return useMutation({
    mutationFn: async (values) => {
      const isNew = typeof values.id === 'string';
      const mutatedProduct = createProductWithFormData(
        values,
        shopId,
        isKitchenNameEnabled,
      ) as MenuProductRequestBody;
      const mutatedPrinterSettings = createPrinterSettingsWithFormData(values);

      const response = isNew
        ? postProduct(shopId, mutatedProduct)
        : putProduct(shopId, mutatedProduct);

      // We need the ID of the new product before we can save the printer
      // settings.
      const mutatedProductId = isNew
        ? (await response).product.id
        : mutatedProduct.product.id;

      return (
        await Promise.all([
          response,
          putProductPrinterSettings(
            shopId,
            mutatedProductId,
            mutatedPrinterSettings,
          ),
        ])
      )[0];
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(getMenuQueryKey(shopId));
      queryClient.invalidateQueries(getMenuProductsQueryKey(shopId));
      queryClient.invalidateQueries(
        getProductPrintSettingsQueryKey(shopId, response.product.id),
      );
    },
  });
};
