import { QueryKey, useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { camelCaseKeys } from 'utilities/objects';

import { ResponseShopGoogleOauthToken } from './types';

export const getQueryKey = (shopId: number): QueryKey => [
  shopId,
  'shopGoogleOauthToken',
];

export const useShopHasGoogleConnection = (
  shopId: number,
):
  | {
      isLoading: true;
      hasGoogleConnection: null;
    }
  | {
      isLoading: false;
      hasGoogleConnection: boolean;
    } => {
  const { authenticatedFetch } = useApi();

  const { data, isLoading, isError } = useQuery({
    queryKey: getQueryKey(shopId),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/google_oauth_token`,
      );
      return camelCaseKeys(response) as ResponseShopGoogleOauthToken;
    },
  });

  if (isLoading) {
    return {
      isLoading: true,
      hasGoogleConnection: null,
    };
  }

  if (isError) {
    console.error('Failed to fetch shop google oauth token');
    return {
      isLoading: false,
      hasGoogleConnection: false,
    };
  }

  return {
    isLoading: false,
    hasGoogleConnection: data.googleOauthToken !== null,
  };
};
