import { useMutation } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuCategoryResponseBody } from 'types/menu/api';

export const useProductSortMutation = (shopId: string, id: number) => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async (productIds: number[]) => {
      const response = await authenticatedFetch.patch(
        `api/management/v2/shops/${shopId}/menus/categories/${id}/products`,
        {
          productIds,
        },
      );

      return response as MenuCategoryResponseBody;
    },
  });
};
