import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { createColumnHelper, Row } from '@tanstack/react-table';

import ContentTile from 'components/shared/content-tile';
import Modal from 'components/shared/slice-modal';
import Table from 'components/shared/table';
import TableAction from 'components/shared/table/action';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useDeleteRegisterPrintingSettingMutation } from 'hooks/register-printing/use-delete-register-printing-setting-mutation';
import { useRegisterPrintingSettingsQuery } from 'hooks/register-printing/use-register-printing-settings-query';
import { useTable } from 'hooks/shared';
import { RegisterPrinter } from 'types/register-printing';
import { showUnexpectedErrorToast } from 'utilities/forms';

import RegisterPrintingForm from './form';
import RegisterPrintingHeader from './header';

type Props = {
  shopId: string;
};

const RegisterPrinting = ({ shopId }: Props) => {
  const { data, isLoading } = useRegisterPrintingSettingsQuery(shopId);

  const [idOfSettingToDelete, setIdOfSettingToDelete] = useState<string | null>(
    null,
  );
  const handleDeleteModalClose = () => setIdOfSettingToDelete(null);
  const handleDeleteModalYes = () => {
    const settingToDelete = data?.find(
      (setting) => setting.id === idOfSettingToDelete,
    );
    if (settingToDelete) {
      deleteSetting(settingToDelete.id, {
        onError: () => showUnexpectedErrorToast(),
        onSuccess: () => {
          toast.success('Print setting successfully deleted!');
        },
        onSettled: () => {
          handleDeleteModalClose();
        },
      });
    }
  };

  const { mutate: deleteSetting } =
    useDeleteRegisterPrintingSettingMutation(shopId);

  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

  const columns = useMemo(() => {
    const helper = createColumnHelper<RegisterPrinter>();

    return [
      helper.accessor('name', {
        enableSorting: true,
        id: 'name',
        header: 'Name',
        cell(ctx) {
          return <Text wrap="truncate">{ctx.getValue()}</Text>;
        },
      }),
      helper.accessor('printMode', {
        enableSorting: true,
        id: 'printMode',
        header: 'Print Mode',
      }),
      helper.accessor('numberOfCopies', {
        enableSorting: true,
        id: 'numberOfCopies',
        header: 'Print Quantity',
      }),
      helper.display({
        id: 'actions',
        enableSorting: false,
        header() {
          return <VisuallyHidden>Actions</VisuallyHidden>;
        },
        cell(ctx) {
          return (
            <>
              <TableAction
                icon="pencil"
                title={`Edit printer setting ${ctx.row.original.name}`}
                onClick={() => ctx.row.toggleExpanded()}
              />
              <TableAction
                icon="trashCan"
                title={`Delete printer setting ${ctx.row.original.name}`}
                onClick={() => setIdOfSettingToDelete(ctx.row.original.id)}
              />
            </>
          );
        },
        meta: {
          isActionColumn: true,
        },
      }),
    ];
  }, []);

  const getRowExpansion = useCallback(
    ({ row }: { row: Row<RegisterPrinter> }) => {
      return (
        <RegisterPrintingForm
          existingPrinter={row.original}
          inputIdPrefix="edit"
          onClose={() => row.toggleExpanded(false)}
          shopId={shopId}
        />
      );
    },
    [shopId],
  );

  const table = useTable({
    columns,
    data: data ?? [],
    getRowCanExpand: () => true,
    getRowExpansion,
    initialState: {
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    },
    state: {
      isLoading,
    },
    chameleonTableTitle: 'Register Printing Settings',
  });

  return (
    <div>
      <Modal
        header="Are you sure?"
        isOpen={!!idOfSettingToDelete}
        onClickNo={handleDeleteModalClose}
        onClickYes={handleDeleteModalYes}
        onRequestClose={handleDeleteModalClose}
        yesButtonText="Delete"
      >
        You are about to delete a printer role. This action cannot be undone.
      </Modal>
      <RegisterPrintingHeader
        toggleCreateForm={() => setIsCreateFormOpen(!isCreateFormOpen)}
      />
      {isCreateFormOpen && (
        <ContentTile title="Create New Print Setting">
          <RegisterPrintingForm
            inputIdPrefix="create"
            onClose={() => setIsCreateFormOpen(false)}
            shopId={shopId}
          />
        </ContentTile>
      )}
      <ContentTile>
        <Table table={table} />
      </ContentTile>
    </div>
  );
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default RegisterPrinting;
