import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  AssetLibraryAsset,
  AssetLibraryCategory,
  networkToDomainAssetLibraryAssets,
} from 'types/asset-library';
import { camelCaseKeys } from 'utilities/objects';

export type UseAssetLibraryResponse = {
  assets: AssetLibraryAsset[];
  isLoading: boolean;
  serviceNames: string[];
};

export const useAssetLibrary = (): UseAssetLibraryResponse => {
  const { authenticatedFetch } = useApi();

  const { data: categoryData, isLoading: isCategoriesLoading } = useQuery({
    queryKey: ['asset-library-categories'],
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `digital-asset-service/api/categories`,
      );

      return camelCaseKeys(response) as AssetLibraryCategory[];
    },
  });
  const { data: assetData, isLoading: isAssetsLoading } = useQuery(
    ['asset-library-assets'],
    async () => {
      const response = await authenticatedFetch.get(
        `digital-asset-service/api/assets`,
      );

      return networkToDomainAssetLibraryAssets(
        response.assets,
      ) as AssetLibraryAsset[];
    },
  );
  const isLoading = isCategoriesLoading || isAssetsLoading;

  let serviceNames: string[] = [];
  const categoryIdToNameMap: Record<string, string> = {};

  if (!isCategoriesLoading) {
    const socialCategory = categoryData?.find(
      (category) => category.name === 'Social',
    );

    if (socialCategory) {
      serviceNames = socialCategory.children.map((subCategory) => {
        categoryIdToNameMap[subCategory.id] = subCategory.name;
        return subCategory.name;
      });
    }
  }

  const assets: AssetLibraryAsset[] = [];

  if (!isLoading && assetData) {
    for (const asset of assetData) {
      const categoryName = categoryIdToNameMap[asset.categoryId];

      if (categoryName) {
        assets.push({
          ...asset,
          categoryName,
        });
      }
    }
  }

  return {
    assets,
    isLoading,
    serviceNames,
  };
};
