import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

export const getShopsQueryKey = (): QueryKey => ['shops'];

export const useShopsQuery = (options?: UseQueryOptions<Shop[]>) => {
  const { authenticatedFetch } = useApi();

  options = {
    staleTime: 300000, // Five minutes.
    ...options,
  };

  return useQuery({
    queryKey: getShopsQueryKey(),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops?role=shop_owner`,
      );

      return camelCaseKeys(response.shops) as Shop[];
    },
    ...options,
  });
};
