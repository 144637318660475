import { useMutation } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { CreateSalesforceCase } from 'types/salesforce';
import { Shop } from 'types/shops/shop';
import { User } from 'types/user';
import { snakeCaseKeys } from 'utilities/objects';

const ORIGIN = 'Owners Portal web';

type MutationData = {
  salesforceCase: CreateSalesforceCase;
  note: string;
  shopId: Shop['shopId'];
  user: User;
  isQualifiedYesOrNo?: 'Yes' | 'No';
};

export const useCreateSalesforceCaseMutation = () => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async ({
      salesforceCase,
      note,
      shopId,
      user,
      isQualifiedYesOrNo,
    }: MutationData) => {
      const description = JSON.stringify({
        Name: `${user.firstName} ${user.lastName}`.trim(),
        Email: user.email,
        ...(!!isQualifiedYesOrNo && { 'Is Qualified': isQualifiedYesOrNo }),
        Note: note,
      });

      const body = {
        ...salesforceCase,
        description,
        origin: ORIGIN,
      };

      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/cases`,
        snakeCaseKeys(body),
      );
      return response.status as string;
    },
  });
};
