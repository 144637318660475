import { useMutation } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuResponseBody } from 'types/menu/api';

export const useCategorySortMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async (ids: number[]) => {
      const data = {
        categoryIds: ids,
      };

      return (await authenticatedFetch.patch(
        `api/management/v2/shops/${shopId}/menus/categories`,
        data,
      )) as MenuResponseBody;
    },
  });
};
