import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { MenuProductsResponseBody } from 'types/menu/api';
import { Shop } from 'types/shops';
import { createIntegerComparator, Direction } from 'utilities/sorting';

type Options = Pick<UseQueryOptions<MenuProductsResponseBody>, 'enabled'>;

export const getMenuProductsQueryKey = (
  shopId: Shop['shopId'] | string,
  channel?: 'online' | 'offline',
): QueryKey => {
  const key = [Number(shopId), 'menu-products'];
  if (channel) {
    key.push(channel);
  }
  return key;
};

export const useMenuProductsQuery = (
  shopId: Shop['shopId'] | string,
  channel?: 'online' | 'offline',
  options?: Options,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getMenuProductsQueryKey(shopId, channel),
    queryFn: async (): Promise<MenuProductsResponseBody> => {
      const param = channel ? `?channel=${channel}` : '';
      const response = (await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/menus/products${param}`,
      )) as MenuProductsResponseBody;
      const comparator = createIntegerComparator('id', {
        direction: Direction.Descending,
      });

      // The API is non-deterministic, which will cause the cache to always miss.
      response.categories.sort(comparator);
      response.products.sort(comparator);
      response.products.forEach((it) => {
        it.types.sort(comparator);
      });

      return response;
    },
    staleTime: 60000,
    ...options,
  });
};
