import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { RegisterPrinter } from 'types/register-printing';
import { upsertBy } from 'utilities/lists';
import { snakeCaseKeys } from 'utilities/objects';
import { networkToDomainRegisterPrinter } from 'utilities/register-printers';

type PrinterSetting = {
  id: string;
  name: string;
  numberOfCopies: number;
  printMode: string;
};

export const useEditRegisterPrintingSettingMutation = (shopId: string) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (printerSetting: PrinterSetting) => {
      const response = await authenticatedFetch.put(
        `api/management/v1/ros/shops/${shopId}/printer_roles/${printerSetting.id}`,
        snakeCaseKeys(printerSetting),
      );

      return networkToDomainRegisterPrinter(response) as RegisterPrinter;
    },

    onSuccess: (data) => {
      // Update the entry for this setting in the cached list.
      queryClient.setQueryData(
        [shopId, 'printSettings'],
        (old: RegisterPrinter[] = []) => upsertBy(old, data, 'id'),
      );
    },
  });
};
