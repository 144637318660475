import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { CustomerOrderProfile } from 'types/customers';
import { camelCaseKeys } from 'utilities/objects';

export const getCustomerOrderProfileQueryKey = (
  shopId: number,
  customerUuid: string,
): QueryKey => [shopId, customerUuid, 'getCustomerOrderProfile'];

export const useCustomerOrderProfileQuery = (
  shopId: number,
  customerUuid: string,
  options?: UseQueryOptions<CustomerOrderProfile>,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getCustomerOrderProfileQueryKey(shopId, customerUuid),
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/v1/shops/${shopId}/marketing/customers/${customerUuid}`,
      );

      return camelCaseKeys(response) as CustomerOrderProfile;
    },
    ...options,
  });
};
