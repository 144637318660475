import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  ColumnFiltersState,
  createColumnHelper,
  getFilteredRowModel,
} from '@tanstack/react-table';

import { Badge, Checkbox, IconButton, IconLink } from 'crust';

import { StockDropdown } from 'components/shared/stock-dropdown';
import Table from 'components/shared/table';
import Text from 'components/shared/text';
import VisuallyHidden from 'components/shared/visually-hidden';
import { useTable } from 'hooks/shared';
import * as paths from 'routes/paths';
import {
  MenuProductsCategory,
  MenuProductsPrinterSetting,
  MenuProductsProduct,
} from 'types/menu/api';
import { Shop } from 'types/shops';
import { nonDisplayCategoryName } from 'utilities/constants';
import { toDollarString, toMoneyString } from 'utilities/currency';

import { useBulkEditContext } from '../context';

import { ProductImage } from './image';

import styles from './styles.module.scss';

type Props = {
  products: MenuProductsProduct[];
  categories: MenuProductsCategory[];
  shop: Shop;
  categoryFilters: ColumnFiltersState;
  setCategoryFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
};

export type MenuTableRowData = {
  categoryId?: number;
  categoryName?: string;
  id: number;
  image?: string | null;
  isAvailableOffline?: boolean;
  isAvailableOnline?: boolean;
  name: string;
  price?: number;
  unavailable: boolean;
  unavailableUntil: string | null;
  printerSettings: MenuProductsPrinterSetting[];
  subRows?: MenuTableRowData[];
};

export const ProductsTable = ({
  products,
  categories,
  shop,
  categoryFilters,
  setCategoryFilters,
}: Props) => {
  const {
    selectedProductIds,
    changeSelectedProductIds,
    clearSelectedProductIds,
  } = useBulkEditContext();

  const data: MenuTableRowData[] = useMemo(
    () =>
      products.map((product) => {
        const productTypesRowData: MenuTableRowData[] = [];
        const category = categories.find(
          (category) => category.id === product.categoryId,
        );

        product.types.forEach((productType) => {
          const { id, name, price, unavailable, unavailableUntil } =
            productType;
          productTypesRowData.push({
            id,
            name,
            price,
            unavailable,
            unavailableUntil,
            printerSettings: product.printerSettings,
          });
        });

        const {
          categoryId,
          id,
          image,
          isAvailableOffline,
          isAvailableOnline,
          name,
          unavailable,
          unavailableUntil,
          printerSettings,
        } = product;

        return {
          categoryId,
          categoryName: category?.name,
          id,
          image,
          isAvailableOffline,
          isAvailableOnline,
          name,
          unavailable,
          unavailableUntil,
          printerSettings,
          subRows: productTypesRowData,
        };
      }),
    [products, categories],
  );

  const columns = useMemo(() => {
    const helper = createColumnHelper<MenuTableRowData>();

    return [
      helper.accessor((row) => row, {
        id: 'collapse',
        header: () => <VisuallyHidden>Expand/Collapse</VisuallyHidden>,
        meta: {
          className: styles.collapseColumn,
        },
        cell: (ctx) => {
          const isExpanded = ctx.row.getIsExpanded();

          if (ctx.row.subRows.length <= 1) {
            return null;
          }

          return (
            <div className={styles.collapseContainer}>
              <IconButton
                icon={isExpanded ? 'chevronUp' : 'chevronDown'}
                aria-label="Collapse"
                onPress={() => ctx.row.toggleExpanded()}
              />
            </div>
          );
        },
      }),
      helper.accessor('id', {
        id: 'checkbox',
        header: (ctx) => {
          const filteredRows = ctx.table.getFilteredRowModel();
          const productIdsInFilteredRows = filteredRows.rows.map(
            (row) => row.original.id,
          );
          const areAllProductsSelected =
            selectedProductIds.size === productIdsInFilteredRows.length;

          return (
            <div className={styles.checkboxContainer}>
              <VisuallyHidden>Product Selection</VisuallyHidden>
              <Checkbox
                isSelected={areAllProductsSelected}
                aria-label="select all products"
                onChange={(isSelected) => {
                  if (isSelected) {
                    changeSelectedProductIds(
                      productIdsInFilteredRows,
                      isSelected,
                    );
                  } else {
                    clearSelectedProductIds();
                  }
                }}
              />
            </div>
          );
        },
        cell: (ctx) => {
          const { id, name, image } = ctx.row.original;
          const isSelected = selectedProductIds.has(id);
          const isSubRow = ctx.row.depth > 0;

          return (
            <div className={styles.checkboxContainer}>
              {!isSubRow ? (
                <Checkbox
                  aria-label={`select ${name}`}
                  isSelected={isSelected}
                  onChange={(isSelected) =>
                    changeSelectedProductIds([id], isSelected)
                  }
                />
              ) : null}
              {!isSubRow && <ProductImage imageUrl={image} imageAlt={name} />}
            </div>
          );
        },
      }),
      helper.accessor('name', {
        id: 'name',
        header: 'Name',
        meta: {
          className: styles.nameColumn,
        },
        cell: (ctx) => <Text clamp>{ctx.row.original.name}</Text>,
      }),
      helper.accessor('price', {
        id: 'price',
        header: 'Price',
        meta: {
          className: styles.priceColumn,
        },

        cell: (ctx) => {
          // if we have a price on the row we have rendered a sub row, so just display the price
          if (ctx.row.original.price) {
            return toMoneyString(toDollarString(ctx.row.original.price));
          }

          // if we are rendering a parent row with only 1 product type, show the price of the single product type
          if (ctx.row.original.subRows?.length === 1) {
            const firstPrice = ctx.row.original.subRows[0]?.price;
            if (firstPrice) {
              return toMoneyString(toDollarString(firstPrice));
            }
          }

          let lowestPrice = 0;

          if (ctx.row.original.subRows) {
            const prices = ctx.row.original.subRows.map(
              (subRow) => subRow.price ?? 0,
            );
            lowestPrice = Math.min(...prices);
          }

          return (
            <Text>{toMoneyString(toDollarString(lowestPrice)) + '+'}</Text>
          );
        },
      }),
      helper.accessor('categoryName', {
        id: 'category',
        header: 'Category',
        filterFn: 'equalsString',
        cell: (ctx) => {
          return <Text>{ctx.row.original.categoryName}</Text>;
        },
      }),
      helper.accessor((row) => row, {
        id: 'availability',
        header: 'Availability',
        meta: {
          className: styles.availabilityColumn,
        },
        cell: (ctx) => {
          const { isAvailableOffline, isAvailableOnline } = ctx.row.original;

          const availability: string[] = [];

          if (isAvailableOnline) {
            availability.push('Online');
          }

          if (isAvailableOffline) {
            availability.push('Register');
          }

          return <Text clamp>{availability.join(', ')}</Text>;
        },
      }),
      helper.accessor((row) => row, {
        id: 'printing',
        header: 'Printing',
        meta: {
          className: styles.nameColumn,
        },
        cell: ({ row }) => {
          const isSubRow = row.depth > 0;
          return !isSubRow ? (
            <Text clamp>
              {row.original.printerSettings.map((it) => it.name).join(', ')}
            </Text>
          ) : null;
        },
      }),
      helper.accessor('id', {
        id: 'stock',
        header: 'Stock',
        meta: {
          className: styles.stockColumn,
        },
        cell: (ctx) => {
          const { id, unavailable } = ctx.row.original;
          const product = products.find((product) => product.id === id);

          if (product) {
            return (
              <StockDropdown
                product={product}
                productTypes={product.types}
                shopId={String(shop.shopId)}
              />
            );
          }

          return unavailable ? (
            <Badge aria-label="product-type-out-of-stock" variant="critical">
              Out of stock
            </Badge>
          ) : (
            <Badge aria-label="product-type-in-stock" variant="success">
              In stock
            </Badge>
          );
        },
      }),
      helper.accessor((row) => row, {
        id: 'actions',
        header: () => <VisuallyHidden>Actions</VisuallyHidden>,
        meta: {
          className: styles.actionsColumn,
          isActionColumn: true,
        },
        cell: (ctx) => {
          const { name, id, categoryId } = ctx.row.original;
          if (categoryId) {
            const category = categories.find(
              (category) => category.id === categoryId,
            );

            const shouldAllowEdit =
              category && category.name !== nonDisplayCategoryName;

            if (shouldAllowEdit) {
              return (
                <IconLink
                  icon="pencil"
                  aria-label={`Edit product ${name}`}
                  href={paths.getMenuItemPath(shop.shopId, id)}
                />
              );
            }
          }

          return null;
        },
      }),
    ];
  }, [
    selectedProductIds,
    categories,
    products,
    shop.shopId,
    changeSelectedProductIds,
    clearSelectedProductIds,
  ]);

  const table = useTable({
    data,
    chameleonTableTitle: 'Menu Items Table',
    columns,
    getSubRows: (row) => row.subRows,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters: categoryFilters,
    },
    onColumnFiltersChange: setCategoryFilters,
    defaultColumn: {
      enableSorting: false,
    },
    hasRowStriping: false,
  });

  return <Table className={styles.table} table={table} />;
};
