import PropTypes from 'prop-types';

import { ShopReferral } from 'components/shop-referral';

import NavigationHeader from './header';
import NavigationItem from './item';
import NavigationSection from './section';

import styles from './styles.module.scss';

const Navigation = ({
  activeShop,
  navigationItems,
  shopId,
  shops,
  trackNavigationItemClick,
  verificationCode,
}) => {
  return (
    <div className={styles.wrapper}>
      <NavigationHeader
        activeShop={activeShop}
        shopId={shopId}
        shops={shops}
        verificationCode={verificationCode}
      />
      <div className={styles.navContent}>
        {navigationItems.map((section) => (
          <NavigationSection key={section.name} title={section.title}>
            {section.items.map((item) => (
              <NavigationItem
                key={item.label}
                {...item}
                trackNavigationItemClick={trackNavigationItemClick}
              />
            ))}
          </NavigationSection>
        ))}
        <ShopReferral shopId={shopId} />
      </div>
    </div>
  );
};

Navigation.propTypes = {
  activeShop: PropTypes.object,
  navigationItems: PropTypes.array,
  shops: PropTypes.array,
  shopId: PropTypes.string.isRequired,
  trackNavigationItemClick: PropTypes.func,
  verificationCode: PropTypes.string,
};

Navigation.defaultProps = {
  activeShop: null,
  navigationItems: [],
  shopId: '',
  shops: [],
  trackNavigationItemClick: () => {},
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default Navigation;
