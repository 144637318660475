import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { ApiRequestError } from 'providers/api/helpers';
import {
  CustomerImport,
  CustomerImportErrors,
  CustomerImportsErrorSchema,
} from 'types/customers/profile/customer-profile-import';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

export const getCustomerImportErrorsQueryKey = (
  shopId: Shop['shopId'],
  uploadId: CustomerImport['id'],
): QueryKey => [shopId, uploadId, 'getCustomerImportErrors'];

export const useCustomerImportErrorsQuery = (
  shopId: Shop['shopId'],
  uploadId: CustomerImport['id'],
  options?: UseQueryOptions<CustomerImportErrors>,
) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getCustomerImportErrorsQueryKey(shopId, uploadId),
    queryFn: async () => {
      try {
        const response = await authenticatedFetch.get(
          `api/management/v1/shops/${shopId}/customer_uploads/${uploadId}/errors`,
        );

        return CustomerImportsErrorSchema.parse(camelCaseKeys(response));
      } catch (error) {
        // A 404 here is fine, it means this upload ID has no errors.
        if (error instanceof ApiRequestError && error.status === 404) {
          return {};
        }

        throw error;
      }
    },
    ...options,
  });
};
