import { Phone } from 'components/phone';
import { NotFoundError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import useFeatureFlag from 'hooks/use-feature-flag';
import { OptimizelyFeatureFlag } from 'types/optimizely-feature-flag';

export const PhonePage = () => {
  const {
    isEnabled: isPhonePageEnabled,
    isLoading: isPhonePageFeatureFlagLoading,
  } = useFeatureFlag(OptimizelyFeatureFlag.PhonePage);

  let content;
  if (isPhonePageFeatureFlagLoading) {
    content = <Loading />;
  } else if (!isPhonePageEnabled) {
    content = <NotFoundError />;
  } else {
    content = <Phone />;
  }

  return (
    <Page name="Phone" title="Phone">
      {content}
    </Page>
  );
};
