import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import {
  CreateCustomerUploadPresignedUrlParams,
  CreateCustomerUploadPresignedUrlResponse,
} from 'types/customers/profile/customer-profile';
import { Shop } from 'types/shops';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getCustomerImportsQueryKey } from './use-customer-imports-query';
import { getProfileCustomersQueryKey } from './use-profile-customers-query';

type UseCreateCustomerUploadMutationParams = {
  customerCsv: File;
  shouldMerge: boolean;
};

export const useCreateCustomerUploadMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  const createCustomerUploadPresignedUrl = async (
    shopId: Shop['shopId'],
    params: CreateCustomerUploadPresignedUrlParams,
  ) => {
    const response = await authenticatedFetch.post(
      `api/management/v1/shops/${shopId}/customer_uploads`,
      snakeCaseKeys(params),
    );
    return camelCaseKeys(response) as CreateCustomerUploadPresignedUrlResponse;
  };

  // TODO: When the changes for getting and checking the status of the latest customer upload are in place
  // We will want to clear the related query key onSuccess here
  return useMutation({
    mutationFn: async ({
      customerCsv,
      shouldMerge,
    }: UseCreateCustomerUploadMutationParams) => {
      const presignedResponse = await createCustomerUploadPresignedUrl(shopId, {
        filename: customerCsv.name,
        filesize: customerCsv.size,
      });

      let isPostToPresignedSuccess = false;

      try {
        const uploadToPresignedUrlResult = await fetch(
          presignedResponse.uploadUrl,
          {
            method: 'put',
            body: customerCsv,
            headers: { 'Content-Type': 'text/csv' },
          },
        );
        isPostToPresignedSuccess = uploadToPresignedUrlResult.ok;
      } catch {
        // Do Nothing
        // We default the success variable for this file post result to false already
        // But we still want to make the final call to tell rez-api that we crated a presigned url, but failed
        // when sending the file itself to that presigned url
      }

      const params = {
        mergeCustomers: shouldMerge,
        status: isPostToPresignedSuccess
          ? 'client_upload_success'
          : 'client_upload_failure',
      };

      await authenticatedFetch.put(
        `api/management/v1/shops/${shopId}/customer_uploads/${presignedResponse.id}`,
        snakeCaseKeys(params),
      );

      return isPostToPresignedSuccess;
    },
    onSuccess: () => {
      // Invalidate the customer imports history query so we display the correct banner
      queryClient.invalidateQueries(getCustomerImportsQueryKey(shopId));

      // Invalidate the profile customers query for the cases that a small import was ran and the csv was processed very quickly, the new customer may now appear
      queryClient.invalidateQueries(getProfileCustomersQueryKey(shopId));
    },
  });
};
