import { useMutation, useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  CreateOrdersReportResponse,
  OrdersReportFormValues,
} from 'types/financials';
import { Shop } from 'types/shops/shop';
import {
  toEndOfDateAbsoluteString,
  toStartOfDateAbsoluteString,
} from 'utilities/date-time';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getOrdersReportsQueryKey } from './use-orders-reports-query';

export const useCreateOfflineOrdersReportMutation = (
  shopId: Shop['shopId'],
  shopTimezone: Shop['timezoneIdentifier'],
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: OrdersReportFormValues) => {
      const fullParams = {
        start: toStartOfDateAbsoluteString(values.dates.start, shopTimezone),
        finish: toEndOfDateAbsoluteString(values.dates.end, shopTimezone),
        forceUpdate: true,
      };
      const query = `${queryString.stringify(snakeCaseKeys(fullParams))}`;
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/offline_order_ledger?${query}`,
      );

      return camelCaseKeys(response) as CreateOrdersReportResponse;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getOrdersReportsQueryKey(shopId));
    },
  });
};
