import Modal from 'components/shared/slice-modal';

type Props = {
  closeModal: () => void;
  isOpen: boolean;
};

export const FiltersModal = (props: Props) => {
  const { closeModal, isOpen } = props;

  return (
    <Modal
      header="Filters"
      isFullScreenMobile
      isOpen={isOpen}
      noButtonText="Clear all"
      onClickNo={closeModal}
      onClickYes={closeModal}
      onRequestClose={closeModal}
      yesButtonText="Show results"
    >
      {/* TODO: Implement filters modal content */}
    </Modal>
  );
};
