import { QueryKey, useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import useApi from 'hooks/use-api';
import {
  GetStatementsParams,
  GetStatementsResponse,
  Statement,
} from 'types/financials/statements';
import { Shop } from 'types/shops';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

// adding new for now until we remove the feature flag for the new statements page and make it the only statements page
export const getStatementsQueryKey = (
  shopId: Shop['shopId'],
  params: GetStatementsParams,
): QueryKey => [shopId, 'statements-new', params];

type Props = {
  shopId: Shop['shopId'];
  params: GetStatementsParams;
};

export const useStatementsQuery = ({ shopId, params }: Props) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: getStatementsQueryKey(shopId, params),
    queryFn: async () => {
      const query = `?${queryString.stringify(snakeCaseKeys(params))}`;

      const response = await authenticatedFetch.get(
        `api/management/v2/shops/${shopId}/statements${query}`,
        {},
        true,
        true,
        true,
      );

      return {
        statements: camelCaseKeys(response.body) as Statement[],
        meta: {
          pages: Number(response.headers.get('X-Total-Pages') ?? 0),
          results: Number(response.headers.get('X-Total') ?? 0),
        },
      } as GetStatementsResponse;
    },
    keepPreviousData: true,
  });
};
