import { CalendarDate } from '@internationalized/date';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { RangeValue } from 'crust';

import useApi from 'hooks/use-api';
import { OrderActivityReport } from 'types/financials';
import { Shop } from 'types/shops/shop';
import { camelCaseKeys, snakeCaseKeys } from 'utilities/objects';

import { getOrderActivityReportsQueryKey } from './use-order-activity-reports-query';

type Values = {
  dates: RangeValue<CalendarDate>;
  onlineOrdersOnly: boolean;
};

export const useCreateOrderActivityReportMutation = (
  shopId: Shop['shopId'],
) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dates, onlineOrdersOnly }: Values) => {
      const params = {
        start: dates.start.toString(),
        end: dates.end.toString(),
        onlyOnlineOrders: onlineOrdersOnly,
      };
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/order_activity_reports`,
        snakeCaseKeys(params),
      );

      return camelCaseKeys(response) as OrderActivityReport;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getOrderActivityReportsQueryKey(shopId));
    },
  });
};
