import { CustomerFeedback } from 'components/customer-feedback';
import { UnexpectedError } from 'components/shared/error';
import Loading from 'components/shared/loading';
import Page from 'components/shared/page';
import { useCurrentShopId, useShopQuery } from 'hooks/shops';

export const CustomerFeedbackPage = () => {
  const shopId = useCurrentShopId();

  const {
    data: shop,
    isError: isShopError,
    isLoading: isShopLoading,
  } = useShopQuery(shopId);

  let content;
  if (isShopLoading) {
    content = <Loading />;
  } else if (isShopError) {
    content = <UnexpectedError />;
  } else {
    content = <CustomerFeedback shop={shop} />;
  }

  return (
    <Page name="customer-feedback" title="Customer Feedback">
      {content}
    </Page>
  );
};
