import {
  ComponentPropsWithoutRef,
  ElementRef,
  ForwardedRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Calendar as AriaCalendar,
  CalendarCell as AriaCalendarCell,
  CalendarGrid as AriaCalendarGrid,
  CalendarGridBody as AriaCalendarGridBody,
  CalendarGridHeader as AriaCalendarGridHeader,
  CalendarGridProps as AriaCalendarGridProps,
  CalendarHeaderCell as AriaCalendarHeaderCell,
  CalendarProps as AriaCalendarProps,
  DateRange as AriaDateRange,
  Heading as AriaHeading,
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  RangeCalendar as AriaRangeCalendar,
  RangeCalendarProps as AriaRangeCalendarProps,
  RangeCalendarStateContext as AriaRangeCalendarStateContext,
  Section as AriaSection,
  Selection as AriaSelection,
} from 'react-aria-components';
import { CalendarDate, DateValue } from '@internationalized/date';
import { useControlledState } from '@react-stately/utils';
import classNames from 'classnames';
import { Merge } from 'type-fest';

import { Icon } from '../icon/icon';
import { IconButton } from '../icon-button-link/icon-button-link';
import { forwardRefWithGenerics } from '../utilities/forward-ref-with-generics';
import { getCrustClassName } from '../utilities/get-crust-class-name';
import { mergeAriaClassName } from '../utilities/merge-aria-class-name';

import './calendar.css';

type CalendarHeaderProps = Omit<ComponentPropsWithoutRef<'header'>, 'children'>;

const getHeaderClassName = getCrustClassName.bind(null, 'calendar-header');

const CalendarHeader = ({ className }: CalendarHeaderProps) => (
  <header className={classNames(getHeaderClassName(), className)}>
    <IconButton
      aria-label="previous month"
      icon="chevronLeft"
      slot="previous"
    />
    <AriaHeading className={getHeaderClassName('heading')} />
    <IconButton aria-label="next month" icon="chevronRight" slot="next" />
  </header>
);

type CalendarGridProps = Omit<AriaCalendarGridProps, 'children'>;

const getGridClassName = getCrustClassName.bind(null, 'calendar-grid');

const CalendarGrid = ({ className }: CalendarGridProps) => (
  <AriaCalendarGrid className={classNames(getGridClassName(), className)}>
    <AriaCalendarGridHeader className={getGridClassName('header')}>
      {(date) => (
        <AriaCalendarHeaderCell className={getGridClassName('heading')}>
          {date}
        </AriaCalendarHeaderCell>
      )}
    </AriaCalendarGridHeader>
    <AriaCalendarGridBody className={getGridClassName('body')}>
      {(date) => (
        <AriaCalendarCell className={getGridClassName('cell')} date={date} />
      )}
    </AriaCalendarGridBody>
  </AriaCalendarGrid>
);

export type CalendarProps<T extends DateValue> = AriaCalendarProps<T>;

const getCalendarClassName = getCrustClassName.bind(null, 'calendar');

export const Calendar = forwardRefWithGenerics(function RangeCalendar<
  T extends DateValue,
>(
  { className, ...props }: CalendarProps<T>,
  ref: ForwardedRef<ElementRef<typeof AriaCalendar>>,
) {
  return (
    <AriaCalendar
      className={mergeAriaClassName(getCalendarClassName(), className)}
      ref={ref}
      {...props}
    >
      <CalendarHeader className={getCalendarClassName('header')} />
      <CalendarGrid className={getCalendarClassName('grid')} />
    </AriaCalendar>
  );
});

type AnchorDateSpyProps = {
  onChangeAnchorDate?: (date: CalendarDate | null) => void;
};

// The RangeCalendar component from RAC doesn't provide an event for when the
// anchor date changes (ie the user is starting to choose a new date range).
const AnchorDateSpy = ({ onChangeAnchorDate }: AnchorDateSpyProps) => {
  const { anchorDate } = useContext(AriaRangeCalendarStateContext);
  const previousValueRef = useRef(anchorDate);

  useEffect(() => {
    // We don't want to call the event handler on first render.
    if (anchorDate !== previousValueRef.current) {
      previousValueRef.current = anchorDate;
      onChangeAnchorDate?.(anchorDate);
    }
  }, [anchorDate, onChangeAnchorDate]);

  return null;
};

type CrustRangeCalendarProps = AnchorDateSpyProps;
export type RangeCalendarProps<T extends DateValue> = Merge<
  AriaRangeCalendarProps<T>,
  CrustRangeCalendarProps
>;

const getRangeCalendarClassName = getCrustClassName.bind(
  null,
  'range-calendar',
);

export const RangeCalendar = forwardRefWithGenerics(function RangeCalendar<
  T extends DateValue,
>(
  { className, onChangeAnchorDate, ...props }: RangeCalendarProps<T>,
  ref: ForwardedRef<ElementRef<typeof AriaRangeCalendar>>,
) {
  return (
    <AriaRangeCalendar
      className={mergeAriaClassName(getRangeCalendarClassName(), className)}
      ref={ref}
      {...props}
    >
      <CalendarHeader className={getRangeCalendarClassName('header')} />
      <CalendarGrid className={getRangeCalendarClassName('grid')} />
      <AnchorDateSpy onChangeAnchorDate={onChangeAnchorDate} />
    </AriaRangeCalendar>
  );
});

// "Custom" is a reserved preset id and disallowed in given definitions.
type NotCustom<S extends string> = S & (S extends 'custom' ? never : S);
type WithCustom<S extends string> = S | 'custom';

type PresetMeta<S extends string> = {
  readonly id: NotCustom<S>;
  readonly label: string;
};

export type RangeValue<T extends DateValue> = {
  readonly start: T;
  readonly end: T;
};

export type PresetDateRange<
  T extends DateValue,
  S extends string,
> = PresetMeta<S> & RangeValue<T>;

export type PresetRangeValue<T extends DateValue, S extends string> = Merge<
  PresetDateRange<T, S>,
  { readonly id: WithCustom<S> }
>;

export type PresetRangeValueProps<T extends DateValue, S extends string> = {
  defaultValue?: NoInfer<PresetRangeValue<T, S>> | null;
  onChange?: (value: NoInfer<PresetRangeValue<T, S>> | null) => void;
  presets: readonly PresetDateRange<T, S>[];
  value?: NoInfer<PresetRangeValue<T, S>> | null;
};

// Convenience type for application components to create a typed state variable.
export type PresetRangeState<
  P extends readonly PresetDateRange<DateValue, string>[],
> = P extends readonly PresetDateRange<infer T, infer S>[]
  ? PresetRangeValue<T, S> | null
  : never;

const getCalendarPresetsClassName = getCrustClassName.bind(
  null,
  'calendar-presets',
);

type CalendarPresetsProps<S extends string, P extends PresetMeta<S>> = {
  className?: string;
  defaultValue?: WithCustom<S>;
  onChange?: (value: WithCustom<S>) => void;
  presets: readonly P[];
  value?: WithCustom<S>;
};

const CalendarPresets = <S extends string, P extends PresetMeta<S>>({
  className,
  defaultValue,
  onChange,
  presets,
  value: propsValue,
}: CalendarPresetsProps<S, P>) => {
  const [value, setValue] = useControlledState(
    propsValue,
    defaultValue || null,
    onChange,
  );

  const selectedKeys = useMemo(() => new Set(value ? [value] : []), [value]);

  // We'll separate the presets into two sections so that we can display two
  // columns on small screens.
  const sections = useMemo(() => {
    // The custom option is not passed as a preset, but is always available.
    const items = [...presets, { id: 'custom', label: 'Custom' }];
    const index = Math.ceil(items.length / 2);

    return [items.slice(0, index), items.slice(index)];
  }, [presets]);

  const handleSelectionChange = useCallback(
    (keys: AriaSelection) => {
      // We don't allow multiple, so should never happen. Narrow keys to Set.
      if (keys === 'all') {
        return;
      }

      // We are given an empty set if the selection is the same.
      const value = Array.from(keys).at(0);

      if (value) {
        setValue(value as S);
      }
    },
    [setValue],
  );

  return (
    <AriaListBox
      aria-label="presets"
      className={classNames(getCalendarPresetsClassName(), className)}
      layout="stack"
      selectionMode="single"
      selectedKeys={selectedKeys}
      onSelectionChange={handleSelectionChange}
    >
      {sections.map((group, index) => (
        <AriaSection
          className={getCalendarPresetsClassName('section')}
          key={index}
        >
          {group.map(({ id, label }) => (
            <AriaListBoxItem
              className={getCalendarPresetsClassName('preset')}
              key={id}
              id={id}
              textValue={label}
            >
              {label}
              <Icon
                className={getCalendarPresetsClassName('checkmark')}
                icon="checkmark"
              />
            </AriaListBoxItem>
          ))}
        </AriaSection>
      ))}
    </AriaListBox>
  );
};

const getPresetRangeCalendarClassName = getCrustClassName.bind(
  null,
  'preset-range-calendar',
);

export type PresetRangeCalendarProps<
  T extends DateValue,
  S extends string,
> = Merge<
  RangeCalendarProps<T>,
  PresetRangeValueProps<T, S> & {
    // When the user re-selects the currently selected preset after clicking
    // custom. Useful for closing an overlay to confirm that user is sticking
    // with the original value;
    onReselectPreset?: (id: S) => void;
  }
>;

export const PresetRangeCalendar = <T extends DateValue, S extends string>({
  className,
  defaultValue,
  onChange,
  onReselectPreset,
  presets,
  value: propsValue,
  ...props
}: PresetRangeCalendarProps<T, S>) => {
  const [value, setValue] = useControlledState(
    propsValue,
    defaultValue || null,
    onChange,
  );

  // If we ever allow this component to be used by itself (eg in a modal), we'll
  // need to set isLocalCustom to false when the component loses focus so
  // that the previously selected value is highlighted again.
  const [isLocalCustom, setIsLocalCustom] = useState(false);

  const { dates, selectedId } = useMemo(() => {
    const shouldUseCustom = isLocalCustom || value == null;

    // When the selected preset changes to custom, clear out the selected
    // calendar dates as feedback that the user can now choose new dates.
    return {
      dates: shouldUseCustom ? null : { start: value.start, end: value.end },
      selectedId: shouldUseCustom ? 'custom' : value.id,
    };
  }, [isLocalCustom, value]);

  // When the user starts choosing dates in the calendar, we want to make sure
  // that the custom preset is highlighted.
  const handleAnchorDateChange = useCallback(() => {
    setIsLocalCustom(true);
  }, []);

  const handleCalendarChange = useCallback(
    (values: AriaDateRange | null) => {
      if (!values) {
        setValue(null);

        return;
      }

      const { start, end } = values;

      setValue({
        start: start as T,
        end: end as T,
        id: 'custom',
        label: 'Custom',
      });
    },
    [setValue],
  );

  const handlePresetChange = useCallback(
    (id: S | 'custom') => {
      const preset = presets.find((it) => it.id === id);

      if (preset) {
        if (preset.id === value?.id) {
          onReselectPreset?.(id as S);
        } else {
          // Return a shallow clone of the preset to avoid unintended mutations
          // by consumers. We are treating the presets as definitions, not the
          // values themselves.
          setValue({ ...preset });
        }

        setIsLocalCustom(false);
      } else {
        setIsLocalCustom(true);
      }
    },
    [onReselectPreset, presets, setValue, value?.id],
  );

  return (
    <AriaRangeCalendar
      className={mergeAriaClassName(
        getPresetRangeCalendarClassName(),
        className,
      )}
      onChange={handleCalendarChange}
      value={dates as RangeValue<T>}
      {...props}
    >
      <CalendarPresets
        presets={presets}
        onChange={handlePresetChange}
        value={selectedId}
      />
      <div className={getPresetRangeCalendarClassName('backdrop')}>
        <div className={getPresetRangeCalendarClassName('calendar')}>
          <CalendarHeader
            className={getPresetRangeCalendarClassName('header')}
          />
          <CalendarGrid className={getPresetRangeCalendarClassName('grid')} />
        </div>
      </div>
      <AnchorDateSpy onChangeAnchorDate={handleAnchorDateChange} />
    </AriaRangeCalendar>
  );
};
